import { useRef } from "react";
const useDisableButton = () => {
  const isClicked = useRef(false);

  const handleClickedDisableButton = () => {
    isClicked.current = !isClicked.current;
  };

  return {
    isClicked: isClicked.current,
    handleClickedDisableButton,
  };
};
export default useDisableButton;