import {ENDPOINTS} from '../../config/constants';
import {apiCall} from '../../middlewares';


const getAvailableListServices = (headers, filter) => {
  return apiCall({
    ...ENDPOINTS.paymentServices.getServices(filter.title, filter.category),
    headers,
  });
};

const getUserListBills = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.paymentServices.getUserBills,
    headers,
    data,
  });
};

const createPaymentService = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.paymentServices.createPaymentService,
    headers,
    data,
  });
};

const getLastServicesPaid = (headers) => {
  return apiCall({
    ...ENDPOINTS.paymentServices.getLastPaid,
    headers,
  });
};

const getServiceCategories = (headers) => {
  return apiCall({
    ...ENDPOINTS.paymentServices.getServiceCategories,
    headers,
  });
};

const reSendVoucher = (headers, transaction_id) => {
  return apiCall({
    ...ENDPOINTS.paymentServices.reSendVoucher,
    data: {transaction_id},
    headers,
  });
};

export default {
  getAvailableListServices,
  getUserListBills,
  createPaymentService,
  getLastServicesPaid,
  getServiceCategories,
  reSendVoucher,
};