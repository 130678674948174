import { useEffect, useState, useCallback, useMemo } from 'react';
import Config from '../config';

import useResidenceConfig from './useResidenceConfig';
import useAmounts from '../hooks/useAmounts';

import { removeAccent } from 'wallet/util';

const useActiveteBalance = () => {
  const [search, setSearch] = useState('');
  const { useRules, useUser } = Config.getInstance().getConfiguration();
  const { currencies_available } = useResidenceConfig();
  const { cryptoAvailable } = useRules();
  const { countryByCurrencyCode } = useAmounts();
  const [activeBalances, setActiveBalances] = useState([]);
  const [deactiveBalances, setDeactiveBalances] = useState([]);
  const {
    user: {
      headers,
      balances,
    },
    updateProfile,
  } = useUser();

  const handleCheckBalance = (item) => {
    setDeactiveBalances(prev => {
      return prev.map(v => {
        if (v.iso_code === item) {
          v.checked = !v.checked;
        }

        return v;
      })
    });
  };

  const onChangeSearch = useCallback((value) => {
    setSearch(value);
  }, [setSearch]);

  const selectedBalances = useMemo(() => {
    return deactiveBalances.filter(select => {
      return !!select.checked;
    }).map(maped => maped.iso_code);
  }, [deactiveBalances]);

  const isValid = useMemo(() => selectedBalances.length > 0, [selectedBalances]);

  const currencies = useMemo(() => {
    return currencies_available.map(c => {
      const cConfig = countryByCurrencyCode(c);

      if(cConfig.is_crypto && !cryptoAvailable.includes(c.toUpperCase())) return null;

      return ({
        iso_code: c,
        name: cConfig.currency_name,
        flag: cConfig.flag_url,
        checked: false,
      })
    }).filter(c => c)
  }, [currencies_available, cryptoAvailable]);

  useEffect(() => {
    setActiveBalances(() => {
      let filtered = currencies.filter(c => {
        return balances.hasOwnProperty(c.iso_code.toLowerCase());
      });

      if (search && search !== '') {
        filtered = filtered.filter(fil => (
          removeAccent(fil.name.toLowerCase()).includes(removeAccent(search.toLowerCase()))) ||
          removeAccent(fil.iso_code.toLowerCase()).includes(removeAccent(search.toLowerCase()))
        )
      }

      return filtered.map(crypto => ({ ...crypto, checked: true }));
    });

    setDeactiveBalances(() => {
      let filtered = currencies.filter(c => {
        return !balances.hasOwnProperty(c.iso_code.toLowerCase());
      });

      if (search && search !== '') {
        filtered = filtered.filter(fil => (
          removeAccent(fil.name.toLowerCase()).includes(removeAccent(search.toLowerCase()))) ||
          removeAccent(fil.iso_code.toLowerCase()).includes(removeAccent(search.toLowerCase()))
        )
      }

      return filtered.map(crypto => ({ ...crypto, checked: false }));
    });

    return () => {
      setActiveBalances([{}]);
      setDeactiveBalances([{}]);
    };
  }, [search, currencies]);

  return {
    search,
    headers,
    isValid,
    selectedBalances,
    activeBalances,
    updateProfile,
    deactiveBalances,
    onChangeSearch,
    handleCheckBalance,
  };
};

export default useActiveteBalance;