import { useReducer, useCallback, useEffect, useState, useMemo } from 'react';

import useAmounts from './useAmounts';
import config from '../config';
import useResidenceConfig from './useResidenceConfig';
import useTransferPrices from './useTransferPrices';
import usePrices from './usePrices';
import useServices from './useServices';
import useSnackBar from 'wallet/hooks/useSnackBar';
import {
  formatMoney,
  isValidEmail,
  isValidBtcWallet,
  isValidUsdtAddress,
  isValidUsdcAddress,
  qrResolveFromVita,
  waitingAction,
  USD_RATE_ROUTES,
} from '../utils';

import { isCryptoCurrency } from 'wallet/util';

const minimumAmountCurrency = (currency, minBtc = 0.00000015, minFiat = 1.00) => {
  currency = currency.toUpperCase();
  return currency === 'BTC'
    ? minBtc
    : isCryptoCurrency(currency)
      ? 1.00
      : minFiat;
};

const isValidDestinatary = (currency, destinatary) => {
  const validation = (isValidBtcWallet(destinatary) || isValidEmail(destinatary) || isValidUsdtAddress(destinatary));
  return isCryptoCurrency(currency) ? validation : isValidEmail(destinatary);
};

const isValidateForm = (
  amount,
  sendCurrency,
  balances,
  destinatary,
  error,
  minimumAmount,
) => {
  const v1 = amount >= minimumAmount;
  const v2 = amount <= balances[sendCurrency.toLowerCase()];
  const v3 = isValidDestinatary(sendCurrency.toUpperCase(), destinatary.trim());
  const v4 = error === '';

  return (v1 && v2 && v3 && v4);
};

const AMOUNT_CHANGE = 'useSent/AMOUNT_CHANGE';
const CURRENCY_CHANGE = 'useSent/CURRENCY_CHANGE';
const DESCRIPTION_CHANGE = 'useSent/DESCRIPTION_CHANGE';
const DESTINATARY_CHANGE = 'useSent/DESTINATARY_CHANGE';
const ADD_ERROR = 'useSent/ADD_ERROR';
const USER_VALID = 'useSent/USER_VALID';
const RESET_FORM = 'useSent/RESET_FORM';
const PRICES_UPDATED = 'useSent/PRICES_UPDATED';

const reducer = (state, action) => {
  const { countryByCurrencyCode, decimalAmounts } = state;
  switch (action.type) {
    case AMOUNT_CHANGE: {
      const {
        sendCurrency,
        sendCurrencyDecimals,
        receiveCurrency,
        receiveCurrencyDecimals,
        // destinatary,
        // error,
        // is_allowed_transaction,
        // minimumAmount,
        // balances,
      } = state;
      const { amount, isReceiveChange } = action.payload;

      let prepareState = {};

      if (amount <= 0) {
        prepareState = {
          amount: 0,
          amountLabel: formatMoney(0, sendCurrency, sendCurrencyDecimals),
          receiveAmount: 0,
          receiveAmountLabel: formatMoney(0, receiveCurrency, receiveCurrencyDecimals),
          isValid: false,
        }
      } else if (isReceiveChange) {
        prepareState = {
          receiveAmount: amount,
          receiveAmountLabel: formatMoney(amount, receiveCurrency, receiveCurrencyDecimals),
        }
      } else {
        prepareState = {
          amount,
          amountLabel: formatMoney(amount, sendCurrency, sendCurrencyDecimals),
        }
      }

      return {
        ...state,
        ...prepareState,
        isReceiveChange,
        // isValid: isValidateForm(
        //   amount,
        //   sendCurrency,
        //   balances,
        //   destinatary,
        //   error,
        //   minimumAmount,
        // ) && is_allowed_transaction,
      };
    }
    case CURRENCY_CHANGE: {
      const {
        sendCurrency,
      } = action.payload;
      const {
        min_amount_sents,
        receiveUserFiat,
        amount,
        destinatary,
        is_allowed_transaction,
        balances,
      } = state;
      const {
        first_name,
        active_currencies: receiveCurrenciesAvailable,
        default_fiat_currency,
        default_currency: defaultReceiveUserFiat,
      } = receiveUserFiat;
      let {
        error,
        receiveCurrency,
        receiveCurrencyDecimals,
      } = state;
      const sendCurrencyConfig = countryByCurrencyCode(sendCurrency);

      if (sendCurrencyConfig.is_crypto) {
        error = receiveCurrenciesAvailable.includes(sendCurrency.toLowerCase())
          ? ''
          : `El usuario ${first_name} no posee activa la moneda ${sendCurrency.toUpperCase()}`;
        receiveCurrency = sendCurrency;
        receiveCurrencyDecimals = sendCurrencyConfig.currency_decimals;
      } else {
        error = '';
        receiveCurrency = default_fiat_currency || defaultReceiveUserFiat;
        receiveCurrencyDecimals = countryByCurrencyCode(receiveCurrency).currency_decimals;
      }

      const receiveCurrencyConfig = countryByCurrencyCode(receiveCurrency);
      const minimumAmount = minimumAmountCurrency(sendCurrency, undefined, min_amount_sents);

      return {
        ...state,
        error,
        sendCurrency,
        sendCurrencyDecimals: sendCurrencyConfig.currency_decimals,
        sendCurrencyConfig,
        receiveCurrency,
        receiveCurrencyDecimals,
        receiveCurrencyConfig,
        amount: 0,
        amountLabel: formatMoney(0, sendCurrency),
        receiveAmount: 0,
        receiveAmountLabel: '0',
        minimumAmount,
        isValid: isValidateForm(
          amount,
          sendCurrency,
          balances,
          destinatary,
          error,
          minimumAmount,
        ) && is_allowed_transaction,
      };
    }
    case DESCRIPTION_CHANGE: {
      const {
        sendCurrency,
        amount,
        destinatary,
        is_allowed_transaction,
        minimumAmount,
        balances,
      } = state;
      let { error } = state;
      const { description } = action.payload;

      if (description?.trim() === '' || description.length > 150) {
        if (description?.trim() === '') {
          error = 'Mensaje es requerido'
        } else {
          error = 'El mensaje permite maximo 150 caracteres'
        }
      } else {
        error = '';
      }

      return {
        ...state,
        description,
        error,
        isValid: isValidateForm(
          amount,
          sendCurrency,
          balances,
          destinatary,
          error,
          minimumAmount,
        ) && is_allowed_transaction,
      };
    }
    case DESTINATARY_CHANGE: {
      const { destinatary } = action.payload;
      let { error } = state;

      if (destinatary !== '') {
        error = null;
      }

      return {
        ...state,
        destinatary,
        error,
        isValid: false,
      };
    }
    case ADD_ERROR: {
      const {
        amount,
        sendCurrency,
        balances,
        destinatary,
        minimumAmount,
        is_allowed_transaction,
      } = state;
      const { error } = action.payload;

      return {
        ...state,
        error,
        isValid: isValidateForm(
          amount,
          sendCurrency,
          balances,
          destinatary,
          error,
          minimumAmount,
        ) && is_allowed_transaction,
      };
    }
    case USER_VALID: {
      const {
        receiveUserFiat,
      } = action.payload;
      const {
        email: destinatary,
        default_fiat_currency,
        default_currency: defaultReceiveUserFiat,
      } = receiveUserFiat;

      const receiveCurrency = default_fiat_currency || defaultReceiveUserFiat;
      const receiveCurrencyConfig = countryByCurrencyCode(receiveCurrency);

      return {
        ...state,
        receiveUserFiat,
        receiveCurrency,
        receiveCurrencyDecimals: receiveCurrencyConfig.currency_decimals,
        receiveCurrencyConfig,
        destinatary,
        error: '',
        usdRateRoute: [],
      };
    }
    case RESET_FORM: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case PRICES_UPDATED: {
      const {
        receiveUserFiat,
        minimumAmount,
        sendCurrencyDecimals,
        receiveCurrency,
        receiveCurrencyDecimals,
        receiveCurrencyConfig,
        sendCurrencyConfig,
        destinatary,
        error,
        is_allowed_transaction,
        isReceiveChange,
        transferPrices: transferPricesState,
        countryUser,
      } = state;
      let {
        transferPrices,
        balances,
      } = action.payload;
      let {
        default_currency,
        default_fiat_currency,
        iso_code_country,
      } = receiveUserFiat;
      let {
        sendCurrency,
        amount,
        receiveAmount,
        usdRateRoute,
        fixedCostSend,
      } = state;
      transferPrices = transferPrices || transferPricesState;
      iso_code_country = iso_code_country.toLowerCase();
      default_currency = default_currency.toLowerCase();
      default_fiat_currency = default_fiat_currency?.toLowerCase() || default_currency;
      sendCurrency = sendCurrency.toLowerCase();

      const countryForPrice = default_fiat_currency === default_currency
        ? iso_code_country
        : receiveCurrencyConfig.iso_code.toLowerCase()

      const pricesCountry = transferPrices.fiat[countryForPrice] || {};
      fixedCostSend = pricesCountry[`${sendCurrency}_is_apply_fixed_cost_in_sents`]
        ? parseFloat(pricesCountry[`${sendCurrency}_fixed_cost_sents`])
        : 0;

      const rate = receiveCurrencyConfig.is_crypto ? 1 : pricesCountry[`${sendCurrency}_sell_send`];

      if (isReceiveChange) {
        amount = (receiveAmount / rate) + fixedCostSend;
      } else {
        receiveAmount = (amount * rate) - fixedCostSend;
      }

      const TR = (amount > 0 && receiveAmount > 0 && amount >= minimumAmount)
        ? (receiveAmount / amount)
        : rate;

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase()) && !sendCurrencyConfig.is_crypto) {
        const us_price = transferPrices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, receiveCurrencyDecimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      } else {
        usdRateRoute = [];
      }

      receiveAmount = decimalAmounts(receiveAmount, receiveCurrencyConfig.iso_code);

      return {
        ...state,
        transferPrices,
        rate: TR,
        usdRateRoute,
        amount,
        amountLabel: formatMoney(amount, sendCurrency, sendCurrencyDecimals),
        receiveAmount,
        receiveAmountLabel: formatMoney(receiveAmount, receiveCurrency, receiveCurrencyDecimals),
        isValid: isValidateForm(
          amount,
          sendCurrency,
          balances,
          destinatary,
          error,
          minimumAmount,
        ) && is_allowed_transaction && receiveAmount > 0,
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const useSent = () => {
  const { useUser, useRules } = config.getInstance().getConfiguration();
  const { user } = useUser();
  const snackbarHook = useSnackBar({ isAutoHide: true });
  const {
    min_amount_sents,
  } = useResidenceConfig();
  const { countryByCurrencyCode, decimalAmounts } = useAmounts();
  let {
    balances,
    handle_default_fiat_currency,
    iso_code_country,
  } = user;

  const { prices } = usePrices();
  const { prices: transferPrices, isReadyPrices: transferPricesIsReady, errorFetch } = useTransferPrices();
  const { findUserFromEmailOrWallet } = useServices();
  const [dest, setDest] = useState('');
  const { cryptoCurrencies, fiatCurrencies } = useRules();

  const currenciesAvailable = useMemo(() => {
    return [...fiatCurrencies, ...cryptoCurrencies];
  }, [cryptoCurrencies, fiatCurrencies]);

  const sendCurrencyConfig = countryByCurrencyCode(handle_default_fiat_currency);

  const initialState = useMemo(() => ({
    amount: 0,
    amountLabel: '0',
    receiveAmount: 0,
    receiveAmountLabel: '0',
    sendCurrency: handle_default_fiat_currency.toUpperCase(),
    sendCurrencyDecimals: sendCurrencyConfig.currency_decimals,
    sendCurrencyConfig,
    receiveCurrency: null,
    receiveCurrencyDecimals: 0,
    description: '',
    destinatary: '',
    isValid: false,
    minimumAmount: minimumAmountCurrency(handle_default_fiat_currency, undefined, min_amount_sents),
    error: null,
    rate: 1,
    fixedCostSend: 0,
    receiveUserFiat: null,
    countryByCurrencyCode,
    decimalAmounts,
    is_allowed_transaction: true,
    min_amount_sents,
    touched: {
      destinatary: false,
      description: false,
      sendCurrency: false,
    },
    usdRateRoute: [],
    currenciesAvailable,
    transferPrices,
    balances,
    isReceiveChange: false,
    countryUser: iso_code_country,
  }), [
    handle_default_fiat_currency,
    sendCurrencyConfig,
    min_amount_sents,
    currenciesAvailable,
    transferPrices,
    countryByCurrencyCode,
    decimalAmounts,
    balances,
    iso_code_country,
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const resetForm = useCallback(() => {
    dispatch({
      type: RESET_FORM,
      payload: initialState,
    });
  }, [initialState]);

  const changeDestinatary = useCallback(destinatary => {
    setDest(destinatary);
    if (state.destinatary !== destinatary) {
      dispatch({
        type: DESTINATARY_CHANGE,
        payload: { destinatary },
      });
    }
  }, [state.destinatary]);

  const changeAmount = useCallback(amount => {
    dispatch({
      type: AMOUNT_CHANGE,
      payload: { amount, isReceiveChange: false },
    });
  }, [dispatch]);

  const changeReceiveAmount = useCallback(amount => {
    dispatch({
      type: AMOUNT_CHANGE,
      payload: { amount, isReceiveChange: true },
    });
  }, [dispatch]);

  const fetchApiVerification = useCallback(() => {
    let isFindUser = true;

    if (isFindUser) {
      if (
        isValidEmail(dest) ||
        isValidUsdtAddress(dest) ||
        isValidUsdcAddress(dest) ||
        isValidBtcWallet(dest)
      ) {
        (async () => {
          try {
            const receiveUser = await findUserFromEmailOrWallet(isValidEmail(dest) ? dest.toLowerCase().trim() : dest);
            const {
              data: {
                attributes: {
                  email,
                  profile_verification,
                  is_sent_receive,
                  status: receiveStatus,
                },
              },
            } = receiveUser;

            if (['accepted', 'profile_update_alerted'].includes(user.profile_verification)) {
              if (user.email === email) {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: 'No puede enviar dinero a usted mismo',
                  },
                });
              } else if (!user.is_sent_available) {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: `No estas habilitado para enviar dinero`,
                  },
                });
              } else if (receiveStatus !== 'active') {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: `El vita usuario no está activo`,
                  },
                });
              } else if (profile_verification !== 'accepted') {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: `El usuario no ha verificado su cuenta`,
                  },
                });
              } else if (!is_sent_receive) {
                dispatch({
                  type: ADD_ERROR,
                  payload: {
                    error: `El usuario no esta habilitado para recibir envios.`,
                  },
                });
              } else {
                dispatch({
                  type: USER_VALID,
                  payload: {
                    receiveUserFiat: {
                      ...receiveUser.data.attributes,
                    },
                  },
                });
              }
            } else {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: 'Verifica tu cuenta para probar esta función.',
                },
              });
            }
          } catch (error) {
            if (error.user === 'vita card not found' || error.user === 'vita card inactive') {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: `El usuario no posee una Vita Card activa.`,
                },
              });
            } else {
              dispatch({
                type: ADD_ERROR,
                payload: {
                  error: `La dirección no existe en Vita Wallet.`,
                },
              });
            }
          }
        })();
      }
    }

    return () => {
      isFindUser = false;
    };
  }, [
    dest,
    findUserFromEmailOrWallet,
    user,
  ]);

  useMemo(() => {
    if (state.amount > 0 && !state.isReceiveChange) {
      dispatch({
        type: PRICES_UPDATED,
        payload: { balances },
      });
    }
  }, [
    state.amount,
    state.isReceiveChange,
    balances
  ]);

  useMemo(() => {
    if (state.receiveAmount > 0 && state.isReceiveChange) {
      dispatch({
        type: PRICES_UPDATED,
        payload: { balances },
      });
    }
  }, [
    state.receiveAmount,
    state.isReceiveChange,
    balances
  ]);

  useEffect(() => {
    return waitingAction(fetchApiVerification);
  }, [dest]);

  useEffect(() => {
    if (state.receiveCurrency && state.sendCurrency) {
      dispatch({
        type: PRICES_UPDATED,
        payload: { transferPrices, balances },
      });
    }
  }, [
    transferPrices,
    balances,
    state.receiveCurrency,
    state.sendCurrency,
  ]);

  const changeCurrency = useCallback(sendCurrency => {
    dispatch({
      type: CURRENCY_CHANGE,
      payload: {
        sendCurrency: sendCurrency.toUpperCase(),
      },
    });
  }, []);

  const changeDescription = useCallback(description => {
    dispatch({
      type: DESCRIPTION_CHANGE,
      payload: { description },
    });
  }, [dispatch]);

  const handleMaxAmount = useCallback(() => {
    const {sendCurrency, countryByCurrencyCode} = state;

    changeAmount(
      decimalAmounts(
        balances[sendCurrency.toLowerCase()],
        countryByCurrencyCode(sendCurrency).iso_code,
      ),
    );
  }, [
    state,
    changeAmount,
    balances,
    decimalAmounts,
  ]);

  const onSetError = (error = '') => {
    dispatch({
      type: ADD_ERROR,
      payload: {
        error,
      },
    });
  };

  const onQrRead = async (qrCode) => {
    try {
      // TODO DAVID: Make usable currency from QR 
      const { address, /* currency */ } = await qrResolveFromVita(qrCode);

      if (address) {
        changeDestinatary(address);
      }
    } catch (error) {
      snackbarHook.showSnackBar({
        text: error.message,
      })
      dispatch({
        type: ADD_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };

  return {
    ...state,
    snackbarHook,
    prices,
    errorFetchMessage: errorFetch,
    onQrRead,
    changeAmount,
    changeReceiveAmount,
    changeCurrency,
    changeDescription,
    changeDestinatary,
    handleMaxAmount,
    resetForm,
    transferPricesIsReady,
    onSetError,
  };
};

export default useSent;
