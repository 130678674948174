import config from 'dumdee/v2/config';
import bankAccountsService from 'dumdee/v2/services/bankAccounts';

const useBankAccount = () => {
  const { useUser } = config.getInstance().getConfiguration();
  const { user } = useUser();

  const searchBeneficiary = (data) => {
    return bankAccountsService.searchBeneficiary(data, user.headers);
  };

  return {
    searchBeneficiary,
  }
};

export default useBankAccount;