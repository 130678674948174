import { useCallback, useState, useEffect, useMemo, useRef } from 'react';

import useServices from './useServices';
import useTransfer from './useTransfer';
import Config from '../config';
import { isValidEmail } from '../utils';

const useFrequentAccount = (
  isInternalAccount = true,
  isGet = false,
  isoCodeCountrySelect = '',
  isTransferAccount = false,
  isSelectFavoriteInGet = true,
) => {
  const { useUser, useFrequentAccounts } =
    Config.getInstance().getConfiguration();
  const { user, userIsReady } = useUser();
  const frequentAccountsHook = useFrequentAccounts();
  const { frequentAccounts, frequentAccountsIsReady } = frequentAccountsHook;
  const {
    form,
    rules,
    country,
    receiveCurrency,
    changeCountry,
    handleChange,
    resetTransferForm,
  } = useTransfer({ isAddFrequentAccount: true, isGetPrices: false });
  const { findUserFromEmailOrWallet } = useServices();

  const [isInternal, changeInternalAccount] = useState(isInternalAccount);
  const [isFetchFavoriteAccounts, setFetchFavoriteAccounts] = useState(false);
  const [idAccountEdit, setIdAccountEdit] = useState(null);
  const idAccount = useRef(null);

  const [isAddAccount, setAddAccount] = useState(false);
  const [favoriteAccounts, setFavoriteAccounts] = useState([]);
  const [favoriteAccountSelect, setFavoriteAccountSelect] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [isEmailExist, setEmailExist] = useState(false);

  useEffect(() => {
    if (frequentAccountsIsReady) {
      const accounts = isInternal
        ? frequentAccounts.internalAccounts.accounts
        : frequentAccounts.internationalAccounts.accounts;

      if (isoCodeCountrySelect) {
        const validAccounts = [];
        accounts.map((account) => {
          if (
            account.destination_iso_code.toLowerCase() ===
            isoCodeCountrySelect.toLowerCase()
          ) {
            validAccounts.push(account);
          }

          return null;
        });

        setFavoriteAccounts(validAccounts);
      } else {
        setFavoriteAccounts(accounts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequentAccountsIsReady]);

  useEffect(() => {
    (async () => {
      if (isInternal && email && email !== '' && isValidEmail(email)) {
        try {
          setEmailExist(false);
          await findUserFromEmailOrWallet(email);
          setEmailExist(true);
        } catch (error) {
          setEmailExist(false);
        }
      }
    })();

    return () => { };
  }, [isInternal, email, findUserFromEmailOrWallet]);

  const isValidInternalUser = useMemo(() => {
    return isValidEmail(email);
  }, [email]);

  const isValidAlias = useMemo(() => {
    return (description && description.length >= 3 && description.length <= 14) || description === '';
  }, [description]);

  const changeFirstName = (value) => {
    setFirstName(value);
  };

  const changeLastName = (value) => {
    setLastName(value);
  };

  const changeEmail = (value) => {
    setEmail(value);
  };

  const changeIdAcountEdit = (value) => {
    setIdAccountEdit(value);
    idAccount.current = value;
  };

  const changeDescription = (value) => {
    setDescription(value);
  };

  const getFavoriteAccountsInternal = useCallback(async () => {
    try {
      const accounts = await frequentAccountsHook.getFavoriteAccountsInternal(
        user.headers,
      );
      setFavoriteAccounts(accounts);
      if (!isTransferAccount && isSelectFavoriteInGet) {
        setFavoriteAccountSelect(accounts[0]);
      }
      setFetchFavoriteAccounts(true);
    } catch (error) {
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getFavoriteAccountsInternational = useCallback(async () => {
    try {
      const accounts =
        await frequentAccountsHook.getFavoriteAccountsInternational(
          user.headers,
        );

      if (isoCodeCountrySelect) {
        const validAccounts = [];
        accounts.map((account) => {
          if (
            account.destination_iso_code.toLowerCase() ===
            isoCodeCountrySelect.toLowerCase()
          ) {
            validAccounts.push(account);
          }

          return null;
        });

        setFavoriteAccounts(validAccounts);
        if (!isTransferAccount && isSelectFavoriteInGet) {
          setFavoriteAccountSelect(
            validAccounts.length > 0 ? validAccounts[0] : null,
          );
        }
      } else {
        setFavoriteAccounts(accounts);
        if (!isTransferAccount && isSelectFavoriteInGet) {
          setFavoriteAccountSelect(accounts[0]);
        }
      }

      setFetchFavoriteAccounts(true);
    } catch (error) {
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isoCodeCountrySelect]);

  const createFavoriteAccountInternal = useCallback(
    async (
      firstNamePayload = '',
      lastNamePayload = '',
      emailPayload = '',
      alias = '',
    ) => {
      try {
        const account = await frequentAccountsHook.createFavoriteAccount(
          user.headers,
          {
            first_name: firstNamePayload ? firstNamePayload : firstName,
            last_name: lastNamePayload ? lastNamePayload : lastName,
            email: emailPayload ? emailPayload : email,
            account_type: 'internal',
            account_alias: alias ? alias : description,
          },
        );
        setAddAccount(true);
        changeIdAcountEdit(account.id);
        return account;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [frequentAccountsHook, user, firstName, lastName, email, description],
  );

  const updateFavoriteAccountInternal = useCallback(() => {
    return frequentAccountsHook.updateFavoriteAccount(
      user.headers,
      idAccountEdit,
      {
        first_name: firstName,
        last_name: lastName,
        email,
        account_type: 'internal',
        account_alias: description,
      },
    );
  }, [
    frequentAccountsHook,
    user,
    idAccountEdit,
    firstName,
    lastName,
    email,
    description,
  ]);

  const createFavoriteAccountInternational = useCallback(
    async (
      destination_iso_code_payload,
      alias = '',
      valuesPayload = null,
      city_payload,
      zipcode_payload,
    ) => {
      try {
        const { values } = form;
        const formValues = valuesPayload ? valuesPayload : values;

        const account = await frequentAccountsHook.createFavoriteAccount(
          user.headers,
          {
            ...formValues,
            destination_iso_code: destination_iso_code_payload
              ? destination_iso_code_payload
              : country,
            account_type: 'international',
            account_alias: alias ? alias : description,
          },
        );
        changeIdAcountEdit(account.id);
        setAddAccount(true);
        return account;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [form, frequentAccountsHook, user, country, description],
  );

  const updateFavoriteAccountInternational = useCallback(() => {
    return frequentAccountsHook.updateFavoriteAccount(
      user.headers,
      idAccountEdit,
      {
        ...form.values,
        destination_iso_code: country,
        account_type: 'international',
        // phone: phone,
        account_alias: description,
        // city: city,
        // zipcode: zipcode,
      },
    );
  }, [form, frequentAccountsHook, user, idAccountEdit, country, description]);

  const deleteFavoriteAccount = useCallback(async () => {
    try {
      await frequentAccountsHook.deleteFavoriteAccount(
        user.headers,
        idAccountEdit || idAccount.current,
        isInternal,
      );
      setAddAccount(false);
      return Promise.resolve('successful');
    } catch (error) {
      return Promise.reject(error);
    }
  }, [frequentAccountsHook, user, idAccountEdit, isInternal, idAccount]);

  const changeFavoriteAccountSelect = (account) => {
    setFavoriteAccountSelect({ ...account });
  };

  const resetInternalForm = () => {
    changeFirstName('');
    changeLastName('');
    changeEmail('');
    changeDescription('');
  };

  const isUser = useCallback(
    (value) => {
      return value === user.email;
    },
    [user],
  );

  const isExistAccount = useCallback(
    (value, iso_code_country = '') => {
      let isExist = false;
      const accounts = isInternal
        ? frequentAccounts.internalAccounts.accounts
        : frequentAccounts.internationalAccounts.accounts;

      accounts.map((account) => {
        if (isInternal) {
          if (value === account.email) {
            isExist = true;
          }
        } else {
          if ((value === account.account_bank || value === account.associate_phone) && iso_code_country.toUpperCase() === account.destination_iso_code.toUpperCase()) {
            isExist = true;
          }
        }

        return null;
      });

      return isFetchFavoriteAccounts ? isExist : true;
    },
    [frequentAccounts, isFetchFavoriteAccounts, isInternal],
  );

  const internalErrorsForm = useMemo(() => {
    const specialFormat = /^(\S)[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0-9\s]*(\S)$/;

    return {
      description: description.length === 0
        ? "Alias es requerido"
        : description.length < 3
          ? 'Mínimo 3 caracteres'
          : description.length > 14
            ? '14 caracteres máximo' :
            !specialFormat.test(description)
              ? 'No se permiten caracteres especiales'
              : '',
      firstName: firstName.length < 2
        ? 'Mínimo 2 caracteres'
        : firstName.length > 50
          ? '50 caracteres máximo'
          : /\d/.test(firstName)
            ? 'No se permiten números'
            : !specialFormat.test(firstName)
              ? 'No se permiten caracteres especiales'
              : '',
      lastName:
        lastName.length < 2
          ? 'Mínimo 2 caracteres'
          : lastName.length > 50
            ? '50 caracteres máximo'
            : /\d/.test(lastName)
              ? 'No se permiten números'
              : !specialFormat.test(lastName)
                ? 'No se permiten caracteres especiales'
                : '',
      email: !isValidEmail(email) ? 'El correo es inválido' : '',
    }
  }, [description, firstName, lastName, email]);

  const isValidInternalForm = useCallback(() => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      internalErrorsForm['firstName'] === '' &&
      internalErrorsForm['lastName'] === '' &&
      internalErrorsForm['description'] === '' &&
      email !== '' &&
      isValidEmail(email) &&
      isEmailExist &&
      isValidAlias &&
      description !== ''
    );
  }, [description, isValidAlias, firstName, lastName, email, isEmailExist, internalErrorsForm]);

  const isValidInternationalForm = useCallback(() => {
    return form && Object.keys(form.errors ? form.errors : {}).length === 0 && isValidAlias && description !== '' && form.isValidForm;
  }, [form, isValidAlias, description]);

  const getListFavoriteAccounts = useCallback((params) => {
    return frequentAccountsHook.getListFavoriteAccounts(
      user,
      params
    );
  }, [frequentAccountsHook, user]);

  const getFrecuentAccount = useCallback((params) => {
    return frequentAccountsHook.getFrecuentAccount(
      user,
      params
    );
  }, [frequentAccountsHook, user]);

  const searchFrecuentAccount = useCallback((params) => {
    return frequentAccountsHook.searchFrequentAccount(
      user,
      params
    );
  }, [frequentAccountsHook, user]);

  useEffect(() => {
    let isFetch = true;

    if (isFetch && isGet && userIsReady && frequentAccountsIsReady) {
      isInternal
        ? getFavoriteAccountsInternal()
        : getFavoriteAccountsInternational();
      isFetch = false;
    }

    return () => {
      isFetch = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsReady, frequentAccountsIsReady]);

  return {
    form,
    isValidAlias,
    internalErrorsForm,
    isEmailExist,
    isValidInternalUser,
    resetTransferForm,
    rules,
    country,
    receiveCurrency,
    changeCountry,
    firstName,
    changeFirstName,
    lastName,
    changeLastName,
    email,
    changeEmail,
    description,
    changeDescription,
    resetInternalForm,
    idAccountEdit,
    changeIdAcountEdit,
    favoriteAccounts,
    changeFavoriteAccountSelect,
    favoriteAccountSelect,
    isAddAccount,
    getFavoriteAccountsInternal,
    getFavoriteAccountsInternational,
    createFavoriteAccountInternal,
    updateFavoriteAccountInternal,
    createFavoriteAccountInternational,
    updateFavoriteAccountInternational,
    deleteFavoriteAccount,
    isExistAccount,
    isUser,
    isFetchFavoriteAccounts,
    handleChange,
    isValidInternalForm,
    isValidInternationalForm,
    changeInternalAccount,
    frequentAccounts,
    frequentAccountsIsReady,
    getListFavoriteAccounts,
    getFrecuentAccount,
    searchFrecuentAccount,
  };
};

export default useFrequentAccount;
