import emailVerficationService from '../services/emailVerificaton';


const useEmailVerification = () => {

  const getEmailVerification = async (code, email) => {
    let data = {
      code,
      email
    };

    try {
      const response = await emailVerficationService.emailVerfication(data);
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  };

  const getResendEmailVerification = async (email) => {
    let data = { email };
    try {
      const response = await emailVerficationService.resendEmailVerfication(
        data
      );
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  };

  return {
    getEmailVerification,
    getResendEmailVerification,
  }
}

export default useEmailVerification;