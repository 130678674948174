import {Dimensions} from 'react-native';

export const width = Dimensions.get('window').width;
export const deviceHeight = Dimensions.get('window').height;
/*export const height = Platform.select({
  ios: deviceHeight,
  android:
    StatusBar.currentHeight > 24
      ? deviceHeight
      : deviceHeight - StatusBar.currentHeight,
});*/
export const height = deviceHeight;
