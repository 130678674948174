import { useEffect, useState, useMemo } from 'react';
import Config from '../config';

import useResidenceConfig from './useResidenceConfig';

const useReceive = (props) => {
  const {
    user,
    isPaymentLink,
  } = props;
  const [wallets, setWallets] = useState([]);
  const [search, setSearch] = useState('');
  const { useRules } = Config.getInstance().getConfiguration();
  const { currencies_available } = useResidenceConfig();
  const { cryptoAvailable, fiatCurrencies } = useRules();
  const {
    email,
    balances,
    active_cryptos,
  } = user;

  const handleChangeSearch = (value) => {
    setSearch(value);
  };

  const isActivableCoin = useMemo(() => {
    return Object.keys(balances).length < currencies_available.length;
  }, [balances, currencies_available]);

  useEffect(() => {

    setWallets(prev => {
      const activeBalances = fiatCurrencies.map(c => ({
        currency: c.toLowerCase(),
        addresses: [{ email: email }],
      }));

      if (!isPaymentLink && active_cryptos) {
        Object.keys(active_cryptos).forEach(c => {
          if (active_cryptos[c].addresses && active_cryptos[c].addresses.length > 0 && cryptoAvailable.includes(c.toUpperCase())) {
            activeBalances.push({
              currency: c,
              addresses: active_cryptos[c].addresses,
            });
          }
        });
      }

      const wallets = [...prev, ...activeBalances].filter(v => v.currency.includes(search.toLowerCase()));

      return wallets;
    });

    return () => {
      setWallets([]);
    }
  }, [search, active_cryptos, fiatCurrencies]);

  return ({
    search,
    wallets,
    isActivableCoin,
    handleChangeSearch,
  });
};

export default useReceive;