import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const getSnackbarsUser = (headers) => {
  return apiCall({
    ...ENDPOINTS.snackbars.getSnackbars,
    headers,
  });
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getSnackbarsUser,
};