import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import snackbarService from '../services/snackbars';
import { NOTIFICATION_KEY, NOTIFICATION_DEFAULT_VALUE } from './state';

const notifications = (useStorage) => {

  const NotificationContext = createContext();
  const { Provider } = NotificationContext;

  const NotificationsProvider = (props) => {

    const [notifications, updateNotification, hydrated] = useStorage(
      NOTIFICATION_KEY,
      NOTIFICATION_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        notifications: notifications || NOTIFICATION_DEFAULT_VALUE,
        updateNotification,
        notificationsIsReady: hydrated
      }),
      [notifications, updateNotification, hydrated]
    );
    return <Provider value={value} {...props} />;
  }

  NotificationsProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useNotifications = () => {
    const context = useContext(NotificationContext);

    if (!context) {
      throw new Error('useNotifications must be used within a NotificationsProvider');
    }

    const { notifications, updateNotification: update, ...rest } = context;

    const clearNotifications = useCallback(() => {
      update({
        ...NOTIFICATION_DEFAULT_VALUE,
      });
    }, [update]);

    const updateNotifications = useCallback(async (headers) => {
      try {
        const response = await snackbarService.getSnackbarsUser(headers);

        if (response.data) {
          update({
            ...notifications,
            list_notification_manager: infoGetListNotification(response.data),
          })
        }
      } catch (error) { }
    }, [update, notifications]);

    const handleNotifications = useCallback((scene, id) => {
      if (notifications.list_notification_manager && notifications.list_notification_manager[scene]) {
        const listScenes = notifications.list_notification_manager[scene].map((item) => {
          if (item.id === id) {
            return { ...item, showNotification: false };
          }
          return item;
        });

        update({
          ...notifications,
          list_notification_manager: {
            ...notifications.list_notification_manager,
            [scene]: listScenes,
          },
        });
      }
    }, [notifications, update]);

    const infoGetListNotification = (data) => {
      let listScenes = {};
      data.forEach((item) => {
        if (item.attributes.scene) {
          if (!listScenes[item.attributes.scene]) {
            listScenes[item.attributes.scene] = [];
            listScenes[item.attributes.scene] = listScenes[
              item.attributes.scene
            ].concat({ ...item.attributes, showNotification: true });
          } else {
            listScenes[item.attributes.scene] = listScenes[
              item.attributes.scene
            ].concat({ ...item.attributes, showNotification: true });
          }
        }
      });
      return listScenes;
    };

    return {
      ...rest,
      notifications,
      handleNotifications,
      clearNotifications,
      updateNotifications,
    }
  }

  return {
    NotificationsProvider,
    useNotifications
  };
};

export default notifications;