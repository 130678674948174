import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import settingsService from '../services/settings';
import config from '../config';

const useCountries = (props) => {
  const {
    isTransfer,
    isFavorites,
  } = props;
  const {useUser} = config.getInstance().getConfiguration();
  const {user} = useUser();
  const {getCountries, getFavoriteCountries} = settingsService;
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});

  const changeCountry = (attributes) => {
    setCountry(attributes);
  };

  useEffect(() => {
    let isFetch = true;
    let isFirst = true;

    (async () => {
      if (isFetch) {
        try {
          const response = isFavorites ? await getFavoriteCountries(user.headers) : await getCountries();
          let data = [];

          response.data.map((country) => {
            const {id, attributes} = country;
            const {is_transfer} = attributes;

            if ((isTransfer && is_transfer) || !isTransfer) {
              const payload = {id, ...attributes};
              if (isFirst) {
                isFirst = false;
                setCountry(payload);
              }
              data.push(payload);
              return null;
            }

            return null;
          });

          setCountries(data);
        } catch (error) {}
      }

      isFetch = false;
    })();

    return () => {
      isFetch = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    countries,
    country,
    changeCountry,
  };
};

useCountries.propTypes = {
  isTransfer: PropTypes.bool,
  isFavorites: PropTypes.bool,
};

useCountries.defaultProps = {
  isTransfer: false,
  isFavorites: false,
};

export default useCountries;
