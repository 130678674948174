import { ENCRYPT, ENV } from 'alejandra/config/env';
import { randomBytes } from 'react-native-randombytes';

const crypto = require('crypto');
const Buffer = require("buffer").Buffer;

const useEncrypt = (props = {}) => {
  const { isEncrypt = true } = props;
  const key = ENCRYPT[ENV].key.split("VW").join("");
  const unparseMethod = 'aVWeVWsVW-VW2VW5VW6-VWgVWcVWm';
  const method = unparseMethod.split("VW").join("");
  const iv = randomBytes(12);
  const unparseBase = 'bVWaVWsVWeVW6VW4';
  const base = unparseBase.split("VW").join("");

  const encrypt = (value) => {
    if (!isEncrypt) {
      return value;
    }

    const cipher = crypto.createCipheriv(method, key, Buffer.from(iv, base));
    let crypted = cipher.update(value, "utf-8", base);
    crypted += cipher.final(base);
    const authTag64 = Buffer(cipher.getAuthTag()).toString(base);
    const iv64 = Buffer(iv).toString(base);

    return `${crypted}__${iv64}__${authTag64}`;
  }

  const decrypt = (value) => {
    if (!isEncrypt) {
      return value;
    }

    try {
      let [encryptedValue, iv, authTag] = value.split('__');
      let decipher = crypto.createDecipheriv(method, key, Buffer.from(iv, base));

      decipher.setAuthTag(Buffer.from(authTag, base));

      let decrypted = decipher.update(encryptedValue, base, 'utf8');
      decrypted += decipher.final('utf8');

      return decrypted;

    } catch (error) {
      return null;
    }
  }

  return {
    encrypt,
    decrypt,
  };
};

export default useEncrypt;