import {Platform} from 'react-native';

import wallpaperLogin from './assets/wallpaper_login.png';
import googleLogo from './assets/google.png';
import registerScreen from './assets/register_screen.png';
import vitaLoader from './assets/vita_loader.png';
import bell from './assets/bell.png';
import bubbleEmpty from './assets/elipse_empty.png';
import verificationWait from './assets/verification_wait.png';
import miniatureWithoutProfile from './assets/users/userPhotoWithoutProfile.png';
import bubbleActiveGreen from './assets/elipse_active_green.png';
import pinRequest from './assets/pin_request.png';
import activeVerticalLine from './assets/active_line.png';
import inActiveVerticalLine from './assets/inactive_line.png';
import bubbleInProgress from './assets/elipse_progress.png';
import visa from './assets/Visa.png';
import masterCard from './assets/MasterCard.png';
import daleCard from './assets/dale_card.png';
import successfulRecharge from './assets/successful_recharge.png';
import activeBell from './assets/active_bell.png';
import successfulSent from './assets/successful_sent.png';
import vita from './assets/vita.png';
import bankAccount from './assets/bank_account.png';
import clock from './assets/clock.png';
import checkSmall from './assets/check_small.png';
import sad from './assets/sad.png';
import starOn from './assets/star_on.png';
import starOff from './assets/star_off.png';
import coffeOff from './assets/coffee_off.png';
import coffeOn from './assets/coffee.png';
import smilleOn from './assets/smile_on.png';
import smilleOff from './assets/smile_off.png';
import paymentWallpaper from './assets/wallpaper_payment.png';
import addButton from './assets/add.png';

export const Assets = {
  images: {
    addButton,
    users: {
      miniatureWithoutProfile,
    },
    sad,
    smilleOff,
    smilleOn,
    paymentWallpaper,
    coffeOff,
    coffeOn,
    clock,
    checkSmall,
    wallpaperLogin,
    starOff,
    starOn,
    daleCard,
    activeBell,
    bankAccount,
    visa,
    successfulSent,
    successfulRecharge,
    masterCard,
    inActiveVerticalLine,
    activeVerticalLine,
    bubbleInProgress,
    bubbleEmpty,
    pinRequest,
    verificationWait,
    bell,
    vita,
    bubbleActiveGreen,
    vitaLoader,
    registerScreen,
    googleLogo,
  },
};

export const Colors = {
  linear_white_transparent_footer: [
    'rgba(255,255,255,0)',
    'rgba(255,255,255,0.1)',
    'rgba(255,255,255,0.6)',
    'rgba(255,255,255,0.6)',
    'rgba(255,255,255,0.6)',
    'rgba(255,255,255,0.8)',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  linear_white_transparent: [
    'rgba(255,255,255,0)',
    'rgba(255,255,255,0.2)',
    'rgba(255,255,255,0.8)',
    '#FFFFFF',
  ],
  linear_white_transparent_full: [
    'rgba(255,255,255,0)',
    'rgba(255,255,255,0.8)',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  linear_gradient_qr_code_inverse: ['#005FEE', '#00BB8D'],
  linear_white: ['#FFFFFF', '#FFFFFF'],
  linear_gradient_qr_code: ['#00BB8D', '#005FEE'],
  linear_gradient_orange: ['#F2994A', '#F2994A'],
  linear_gradient_gray: ['#F2F2F2', '#F2F2F2'],
  yellow_light: 'rgb(255,251,244)',
  yellow_light_2: 'rgba(222, 214, 33, 0.16)',
  gray_light: '#F2F2F2',
  CF2F2F2: '#F2F2F2',
  blue_CTA: '#005FEE',
  white: '#FFFFFF',
  black: '#333333',
  green_CTA: '#00BB8D',
  green_CTA_alpha16: 'rgba(0,187,141,0.16)',
  green_CTA_3: '#14AD92',
  gray_hard: '#BDBDBD',
  gray_dark_2: '#6c6c6c',
  gray_dark: '#333333',
  red: '#FF0000',
  red_light: 'rgba(221, 83, 53, 0.16)',
  orange: '#F2994A',
  gray_light_2: '#4F4F4F',
  gray_light_3: '#777777',
  gray_light_4: '#828282',
  gray_ligth_5: '#FCFDFF',
  gray_light_7: 'rgba(28, 57, 101, 0.4)',
  green_olive: 'rgba(92, 205, 73, 0.16)',
  green_dark: '#27AE10',
  yellow_dark: '#AFA90B',
  red_dark: '#DD5335',
};

// box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.12);
export const ShadowProperties =
  Platform.OS === 'ios'
    ? {
        shadowColor: '#000',
        shadowOffset: {
          width: 2,
          height: 4,
        },
        shadowOpacity: 0.12,
        shadowRadius: 16,
        elevation: 10,
        overflow: 'visible',
      }
    : {
        shadowColor: '#000',
        shadowOffset: {
          width: 2,
          height: 4,
        },
        shadowOpacity: 0.12,
        shadowRadius: 16,
        elevation: 15,
      };

export const Fonts = {
  regular: 'Lato-Regular',
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  medium: 'Lato-Medium',
};

// const pixelRatio = PixelRatio.get();
// const deviceHeight = height;
// const deviceWidth = width;

const adjustFontSize = (size) => {
  return size;
  /*if (pixelRatio >= 2 && pixelRatio < 3) {
    // iphone 5s and older Androids
    if (deviceWidth < 360) {
      return size * 0.95;
    }
    // iphone 5
    if (deviceHeight < 667) {
      return size;
      // iphone 6-6s
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.15;
    }
    // older phablets
    return size * 1.25;
  }
  if (pixelRatio >= 3 && pixelRatio < 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
    }
    // Catch other weird android width sizings
    if (deviceHeight < 667) {
      return size * 1.15;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.2;
    }
    // catch larger devices
    // ie iphone 6s plus / 7 plus / mi note 等等
    return size * 1.27;
  }
  if (pixelRatio >= 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
      // Catch other smaller android height sizings
    }
    if (deviceHeight < 667) {
      return size * 1.2;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.25;
    }
    // catch larger phablet devices
    return size * 1.4;
  }
  return size;*/
};

export const FontSizes = {
  fontExtraSmall: adjustFontSize(8),
  fontLightSmall: adjustFontSize(10),
  fontSmall: adjustFontSize(12),
  fontNormal: adjustFontSize(14),
  fontExtraNormal: adjustFontSize(16),
  fontBig: adjustFontSize(24),
  fontMax: adjustFontSize(20),
  fontLightMax: adjustFontSize(32),
  fontUltraMax: adjustFontSize(34),
};
