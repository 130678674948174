import AsyncStorage from '@react-native-async-storage/async-storage';
import CIO from 'react-native-customerio';
import {Platform} from 'react-native';

import {CIO_SITE_ID, CIO_API_KEY} from 'alejandra/config/constants';

const useCustomerio = () => {
  const cio = new CIO(CIO_SITE_ID, CIO_API_KEY);
  const OS = Platform.OS;

  const identifyUser = (id, userData, referrerData) => {
    cio.identify(parseInt(id), {
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      created_at: parseInt(Date.parse(userData.created_at) / 1000),
      birthdate: userData.birthdate
        ? parseInt(Date.parse(userData.birthdate) / 1000)
        : null,
      gender: userData.gender,
      country_birth: userData.residence_country_name,
      country_residence: userData.country_name,
      state_residence: userData.state_name,
      city_residence: userData.city_name,
      profession: userData.ocupation,
      phone: userData.phone,
      avatar: userData.avatar,
      category: userData.category,
      profile_verification: userData.profile_verification,
      _timestamp: parseInt(Date.now() / 1000),
      referrer_id: referrerData.data ? parseInt(referrerData.data.id) : null,
      _last_visit: parseInt(Date.now() / 1000),
    });
  };

  const trackEvents = (id, name, data) => {
    cio.track(parseInt(id), {
      name,
      data: {
        ...data,
        device: OS,
      },
    });
  };

  const addDevices = (id, device_token) => {
    cio.addDevice(parseInt(id), device_token, OS, {
      last_used: parseInt(Date.now() / 1000),
    });
  };

  const trackPages = async (screen_name) => {
    try {
      let value = await AsyncStorage.getItem('USER_DUMDEE');
      const user = JSON.parse(value);

      if(user) {
        if (user.id && screen_name) {
          cio.trackPageView(parseInt(user.id), screen_name);
        }
      }
    } catch (error) {}
  };

  return {
    identifyUser,
    trackEvents,
    addDevices,
    trackPages,
  };
};

export default useCustomerio;
