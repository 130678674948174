import { useCallback, useState, useEffect, useMemo } from 'react';
import { Platform, Dimensions, StatusBar } from 'react-native';

const ANGIE_WIDTH = 919;
const SMALL_WIDTH = 359;
const S_WIDTH = 1280;
const M_WIDTH = 1440;
const TALL_SCREEN_HEIGHT = 940;

const getWidth = () =>
  Platform.OS === 'web'
    ? global.window.innerWidth ||
    global.document.documentElement.clientWidth ||
    global.document.body.clientWidth
    : Dimensions.get('window').width;

const getHeight = () =>
  Platform.OS === 'web'
    ? global.window.innerHeight ||
    global.document.documentElement.clientHeight ||
    global.document.body.clientHeight
    : Dimensions.get('window').height;

const getFullHeight = () =>
  Platform.OS === 'web'
    ? global.window.innerHeight ||
    global.document.documentElement.clientHeight ||
    global.document.body.clientHeight
    : Dimensions.get('window').height + StatusBar.currentHeight;

const useWindow = () => {
  const [windowSize, setWindowSize] = useState({
    width: getWidth(),
    height: getHeight(),
    fullHeight: getFullHeight(),
  });

  useEffect(() => {
    if (Platform.OS === 'web') {
      let timeoutId = null;
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setWindowSize(prev => ({
            ...prev,
            width: getWidth(),
            height: getHeight(),
            fullHeight: getFullHeight(),
          }));
        }, 150);
      };

      global.window.addEventListener('resize', resizeListener);

      return () => {
        setWindowSize({
          width: getWidth(),
          height: getHeight(),
          fullHeight: getFullHeight(),
        });
        global.window.removeEventListener('resize', resizeListener);
      };
    }
  }, []);

  const isAngie = useCallback(() => {
    return Platform.OS === 'web' ? windowSize.width >= ANGIE_WIDTH : false;
  }, [windowSize]);

  const isSmall = useMemo(() => {
    return windowSize.width < SMALL_WIDTH;
  }, [windowSize]);

  const isSizeS = useMemo(() => {
    return windowSize.width < S_WIDTH;
  }, [windowSize]);

  const isSizeM = useMemo(() => {
    return windowSize.width < M_WIDTH;
  }, [windowSize]);

  const isWeb = useMemo(() => {
    return Platform.OS === 'web';
  }, []);

  const isTall = useMemo(() => (
    isAngie() && windowSize.height >= TALL_SCREEN_HEIGHT
  ), [isAngie, windowSize]);

  return {
    ...windowSize,
    isAngie,
    isSmall,
    isSizeS,
    isSizeM,
    isWeb,
    isTall,
  };
};

export default useWindow;
