import config from './config';
import {
  ALEJANDRA,
  ANGIE,
  EXTERNAL_LINKS,
  QA_ENVIRONMENT,
  PROD_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  DEV_ENVIRONMENT,
  STAGE_ENVIRONMENT,
} from './config/constants';

export * from './services';
export * from './hooks';
export * from './utils';
export * from './contexts/state';

export const useUser = () => {
  const payload = config.getInstance().getConfiguration().useUser();
  return payload;
};

export const useSettings = () => {
  const payload = config.getInstance().getConfiguration().useSettings();
  return payload;
};

export const useBanks = () => {
  const payload = config.getInstance().getConfiguration().useBanks();
  return payload;
};

export const useSidebar = () => {
  const payload = config.getInstance().getConfiguration().useSidebar();
  return payload;
};

export const useBanners = () => {
  const payload = config.getInstance().getConfiguration().useBanners();
  return payload;
};

export const useAlerts = () => {
  const payload = config.getInstance().getConfiguration().useAlerts();
  return payload;
};

export const useRules = () => {
  const payload = config.getInstance().getConfiguration().useRules();
  return payload;
};

export const useSession = () => {
  const payload = config.getInstance().getConfiguration().useSession();
  return payload;
};

export const useFrequentAccounts = () => {
  const payload = config.getInstance().getConfiguration().useFrequentAccounts();
  return payload;
};

export const useVersions = () => {
  const payload = config.getInstance().getConfiguration().useVersionsContext();
  return payload;
};

export const useBusinessLots = () => {
  const payload = config.getInstance().getConfiguration().useBusinessLots();
  return payload;
};

export const useNotifications = () => {
  const payload = config.getInstance().getConfiguration().useNotifications();
  return payload;
};

export const env = () => {
  const payload = config.getInstance().getConfiguration().env;
  return payload;
};

export const DUMDEE_CONSTANTS = {
  ALEJANDRA,
  ANGIE,
  EXTERNAL_LINKS,
  QA_ENVIRONMENT,
  PROD_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  DEV_ENVIRONMENT,
  STAGE_ENVIRONMENT,
};
