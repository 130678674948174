import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// import * as RNLocalize from 'react-native-localize';

import * as resources from './languages';
import {log} from '../util';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) =>
    callback(/*RNLocalize.getLocales()[0].languageTag*/ 'es'),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    ns: ['common', 'auth'],
    defaultNS: 'common',
    // eslint-disable-next-line no-undef
    //debug: __DEV__,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })
  .then(() => {})
  .catch((error) => log('Error i18n', error));
