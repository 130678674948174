import { useEffect, useCallback, useMemo, useRef } from 'react';
import moment from 'moment';

import Config from '../config';
import { isTransferPricesRequired } from '../utils';

const useUpdateTransferPrices = (route) => {
  const { useUser, usePricesContext } = Config.getInstance().getConfiguration();
  const { user } = useUser();
  const { getTransferPricesContext, pricesTransferIsReady, prices } = usePricesContext();
  const {
    transferPrices: {
      prices: transferPrices,
      expiry,
    },
    fetchError,
  } = prices;
  let isFetchApi = false;
  const ref = useRef(null);

  const delay = useMemo(() => {
    const valid_until = expiry;
    const milliseconds = moment(valid_until)
      .add(10, 'milliseconds')
      .diff(moment().utc(0), 'milliseconds');

    if (
      route &&
      pricesTransferIsReady &&
      Object.keys(transferPrices).length > 0
    ) {
      return milliseconds;
    }

    if (
      route &&
      Object.keys(transferPrices).length < 1 &&
      fetchError !== ""
    ) {
      return milliseconds;
    }
  }, [expiry, pricesTransferIsReady, route]);

  const isGetPrices = useMemo(() => isTransferPricesRequired(route), [route]);

  const getPrices = useCallback(async (force = false) => {
    try {
      if (user && !isFetchApi) {
        isFetchApi = true;
        await getTransferPricesContext(user.headers, force);
        isFetchApi = false;
      }
    } catch (error) {
      isFetchApi = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, prices]);

  useEffect(() => {
    if (isTransferPricesRequired(route) && Object.keys(transferPrices).length < 1) {
      getPrices(true);
    }
  }, [route]);

  useEffect(() => {
    const interval = (callback) => {
      var id = setInterval(handleCallback, delay);

      ref.current = id;

      function handleCallback() {
        clearInterval(id);
        callback();
      }
    }

    if (delay && isGetPrices) {
      interval(getPrices);
    }

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    }
  }, [delay]);

  return {
    prices,
    getPrices,
  };
};

export default useUpdateTransferPrices;