import React, {useEffect, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';
import {transformScreenNavigation} from '../../../utils';

const prepareSceneName = (routeName) => {
  let name;
  if (routeName === '/login') {
    name = 'Login';
  } else if (routeName === '/dashboard') {
    name = 'Dashboard';
  } else {
    Object.entries(transformScreenNavigation).forEach(([key, value]) => {
      if (value === routeName) {
        name = key;
      }
    });
  }
  return name;
};

const usePageViews = (ref, onStateChange) => {
  let location = useLocation();
  React.useEffect(() => {
    if (ref !== null) {
      ref.current = {
        getCurrentRoute: () => ({name: prepareSceneName(location.pathname)}),
      };

      onStateChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

const ChildrenNavigation = (props) => {
  const {onStateChange, refNavigation, onReady} = props;
  usePageViews(refNavigation, onStateChange);

  useEffect(() => {
    onReady();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

ChildrenNavigation.prototypes = {
  onStateChange: PropTypes.func,
  onReady: PropTypes.func,
  refNavigation: PropTypes.any,
};

ChildrenNavigation.defaultProps = {
  onStateChange: () => {},
  onReady: () => {},
  refNavigation: null,
};

export const NavigationContainer = forwardRef((props, ref) => {
  const {children} = props;

  return (
    <Router>
      <ChildrenNavigation {...props} refNavigation={ref} />
      {children}
    </Router>
  );
});

export const useLinking = () => {
  return {
    getInitialState: () =>
      new Promise((resolve, reject) => {
        resolve(JSON.stringify({state: {param: 'aqui'}}));
      }),
  };
};

export const useFocusEffect = useEffect;

export const useIsFocused = () => true;

export const useNavigation = () => {};

export const CommonActions = {
  reset: (config) => {
    return config;
  },
};

export const StackActions = {
  replace: (name, params) => {
    return {
      index: 0,
      routes: [
        {
          name,
          params,
        },
      ],
    };
  },
};
