import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import config from '../config';
import useStorageCreator from '../hooks/useStorageCreator';
import userContext from '../contexts/user';
import settingsContext from '../contexts/settings';
import banksContext from '../contexts/banks';
import bannersContext from '../contexts/banners';
import alertsContext from '../contexts/alerts';
import rulesContext from '../contexts/rules';
import sessionContext from '../contexts/session';
import pricesContext from '../contexts/prices';
import sidebarContext from '../contexts/sidebar';
import frequentAccountsContext from '../contexts/frequentAccounts';
import residenceConfigContext from '../contexts/residenceConfig';
import versionsContext from '../contexts/versions';
import businessLotsContext from '../contexts/businessLots';
import notificationsContext from '../contexts/notifications';
import { API_URL, ANGIE, QA_ENVIRONMENT, WEB_APP_URL, SESSION_TIME } from '../config/constants';

const Dumdee = (props) => {
  const { storage, children, env, target, apiUrl } = props;
  const useStorage = useStorageCreator(storage, props);
  const { UserProvider, useUser } = userContext(useStorage);
  const { SettingsProvider, useSettings } = settingsContext(useStorage);
  const { BanksProvider, useBanks } = banksContext(useStorage);
  const { BannersProvider, useBanners } = bannersContext(useStorage);
  const { AlertsProvider, useAlerts } = alertsContext(useStorage);
  const { RulesProvider, useRules } = rulesContext(useStorage);
  const { SessionProvider, useSession } = sessionContext(useStorage);
  const { PricesProvider, usePricesContext } = pricesContext(useStorage);
  const { SidebarProvider, useSidebar } = sidebarContext(useStorage);
  const { ResidenceConfigProvider, useResidenceConfigContext } = residenceConfigContext(useStorage);
  const { VersionsProvider, useVersionsContext } = versionsContext(useStorage);
  const { FrequentAccountsProvider, useFrequentAccounts } = frequentAccountsContext(useStorage);
  const { BusinessLotsProvider, useBusinessLots } = businessLotsContext(useStorage);
  const { NotificationsProvider, useNotifications } = notificationsContext(useStorage);

  config.getInstance().setConfiguration({
    useUser,
    useSettings,
    useBanks,
    useBanners,
    useAlerts,
    useRules,
    useSession,
    usePricesContext,
    useSidebar,
    useFrequentAccounts,
    useResidenceConfigContext,
    useVersionsContext,
    useBusinessLots,
    useNotifications,
    sessionTime: SESSION_TIME[env],
    env: {
      apiUrl: apiUrl ? apiUrl : API_URL[env],
      target: env,
      appName: target,
      webAppUrl: WEB_APP_URL[env],
    },
  });

  if (axios && axios.defaults) {
    axios.defaults.baseURL = apiUrl ? apiUrl : API_URL[env];
  }

  return (
    <SessionProvider>
      <VersionsProvider>
        <SettingsProvider>
          <BanksProvider>
            <BannersProvider>
              <AlertsProvider>
                <RulesProvider>
                  <UserProvider>
                    <ResidenceConfigProvider>
                      <SidebarProvider>
                        <PricesProvider>
                          <FrequentAccountsProvider>
                            <BusinessLotsProvider>
                              <NotificationsProvider>
                                {children}
                              </NotificationsProvider>
                            </BusinessLotsProvider>
                          </FrequentAccountsProvider>
                        </PricesProvider>
                      </SidebarProvider>
                    </ResidenceConfigProvider>
                  </UserProvider>
                </RulesProvider>
              </AlertsProvider>
            </BannersProvider>
          </BanksProvider>
        </SettingsProvider>
      </VersionsProvider>
    </SessionProvider>
  );
};

Dumdee.propTypes = {
  storage: PropTypes.any.isRequired,
  temporaryStorage: PropTypes.any,
  children: PropTypes.shape({}).isRequired,
  env: PropTypes.string.isRequired,
  target: PropTypes.string,
  apiUrl: PropTypes.string,
};

Dumdee.defaultProps = {
  temporaryStorage: null,
  env: QA_ENVIRONMENT,
  target: ANGIE,
  apiUrl: '',
};

export default Dumdee;
