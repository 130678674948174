import { useState } from 'react';

const useSnackBar = (props = {}) => {
  const { isAutoHide = false, hidingTime = 2500 } = props;
  const [isVisible, setVisible] = useState(false);

  const [state, setState] = useState({
    text: '',
    image: '',
    isMinImage: false,
    isBottom: true,
    isRight: false,
    isImage: false,
  });

  const showSnackBar = (data = {}) => {
    setVisible(true);

    setState(prev => ({
      ...prev,
      ...data,
    }));

    if (isAutoHide) {
      setTimeout(() => setVisible(false), hidingTime);
    }
  };

  const onTimeOut = () => {
    setVisible(false);
  };

  return {
    ...state,
    onTimeOut,
    isVisible,
    showSnackBar,
  };
};

export default useSnackBar;
