import { useMemo } from "react";
import { Platform } from 'react-native';
import { AppEventsLogger, Settings } from "react-native-fbsdk-next";

import { ENVIRONMENT as ENV } from 'alejandra/config/constants';

const useFacebook = () => {
  const isAvailable = useMemo(() => ENV === 'prod', [ENV]);

  const initialateSDK = () => {
    try {
      Settings.initializeSDK();
    } catch {}
  }

  const pageViewEvent = () => {
    if (isAvailable) {
      AppEventsLogger.logPageView();
    } else {
      if (Platform.OS === 'web') {
        console.log(`Event Facebook -> pageViewEvent`);
      }
    }
  }

  const completeRegisterEvent = () => {
    if (isAvailable) {
      AppEventsLogger.logEvent(AppEventsLogger.AppEvents.CompletedRegistration, {
        [AppEventsLogger.AppEventParams.RegistrationMethod]: "email",
      });
    } else {
      if (Platform.OS === 'web') {
        console.log(`Event Facebook -> completeRegisterEvent`);
      }
    }
  }

  const sendEventFacebook = (nameEvent, nameParamEvent) => {
    if (isAvailable) {
      const { logEvent, AppEventParams, AppEvents } = AppEventsLogger

      logEvent(AppEvents[nameEvent], {
        [AppEventParams.RegistrationMethod]: nameParamEvent
      })
    } else {
      if (Platform.OS === 'web') {
        console.log(`Event Facebook -> ${nameEvent}`);
      }
    }
  }

  return {
    initialateSDK,
    completeRegisterEvent,
    pageViewEvent,
    sendEventFacebook
  };
};

export default useFacebook;