// MANUAL_DEPLOY 06

/**
 Constante para definir el entorno en el que se encuentra la app
 Posibles valores:
 - production
 - stage
 - qa
 - dev
 - local 
 **/

export const ENV = 'qa';

// Constante para definir la version de la API
export const API_VERSION = 'v1';

// Constante para definir la version de la mobile app
export const MOBILE_VERSION = '6.3.8';

// Constante para saber en que entorno esta trabajando
export const IS_MOBILE = false;

export const ENCRYPT = {
  prod: {
    key: '95mtVWM#xNVW1A$qVWR3w6VWHxMcVWCpu@VWA5GjVWStUk',
  },
  stage: {
    key: '#Ke@VW58Z9VWX!Y1VWmVXjVW2V59VWbCc0VWz!ZDVWWC$X',
  },
  qa: {
    key: '#Ke@VW58Z9VWX!Y1VWmVXjVW2V59VWbCc0VWz!ZDVWWC$X',
  },
  dev: {
    key: 'Dg57VWwzfnVWmGhyVWAk*pVWmW*nVW7e$*VWfmf1VWTKXA',
  },
  local: {
    key: 'Dg57VWwzfnVWmGhyVWAk*pVWmW*nVW7e$*VWfmf1VWTKXA',
  },
};

export const CUSTOMERIO = {
  prod: {
    cio_site_id: '7cc59f3b22622b687933',
    cio_api_key: '8d4ceb01617a0e45c9f2',
  },
  stage: {
    cio_site_id: 'bb7b3e6df9b5090e0ea7',
    cio_api_key: '2407933cbbd6822ca70b',
  },
  qa: {
    cio_site_id: 'bb7b3e6df9b5090e0ea7',
    cio_api_key: '2407933cbbd6822ca70b',
  },
  dev: {
    cio_site_id: 'bb7b3e6df9b5090e0ea7',
    cio_api_key: '2407933cbbd6822ca70b',
  },
  local: {
    cio_site_id: 'bb7b3e6df9b5090e0ea7',
    cio_api_key: '2407933cbbd6822ca70b',
  },
};

export const RECAPTCHA = {
  // prod: '6LfAc6kZAAAAAHV5806CcNELiVrJlByYebpwujwn',
  prod: '6LezpmsfAAAAAGHJjkr1m83BiWiXTLkLTFwdOSqX',
  // stage: '6LemyGsfAAAAAB2JCQKud0Cvfin-q5DQS2x3lTsB', Discomment to activate captcha
  stage: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  // qa: '6LemyGsfAAAAAB2JCQKud0Cvfin-q5DQS2x3lTsB',, Discomment to activate captcha
  qa: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  dev: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  local: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};

// ENDPOINTS de la aplicacion en los diferentes entornos
// Nota: las claves de este objeto deben coincidir con el (o los) valores de la constante ENV
export const ENDPOINTS = {
  API: {
    prod: 'https://api.vitawallet.io/api',
    stage: 'https://api.stage.vitawallet.io/api',
    qa: 'https://api.qa.vitawallet.io/api',
    dev: 'http://192.168.1.115:3000/api',
    local: 'http://localhost:3000/api',
  },
  HOME_LINKING: {
    prod: 'https://tracking.vitawallet.io',
    stage: 'https://tracking.stage.vitawallet.io',
    qa: 'https://tracking.qa.vitawallet.io',
    dev: '',
    local: 'http://localhost:3005',
  },
  DEEP_LINKING: {
    prod: 'https://app.vitawallet.io',
    stage: 'https://stage.vitawallet.io',
    qa: 'https://qa.vitawallet.io',
    dev: '',
    local: '',
  },
  GOOGLE_SIGNIN_CONFIG: {
    prod: {},
    stage: {
      scopes: [],
      webClientId:
        '1080238478021-d2m16vo2pd4anp5bp7ja3ufccr62hs2e.apps.googleusercontent.com',
      offlineAccess: true,
      forceCodeForRefreshToken: true,
    },
    qa: {
      scopes: [],
      webClientId:
        '1080238478021-d2m16vo2pd4anp5bp7ja3ufccr62hs2e.apps.googleusercontent.com',
      offlineAccess: true,
      forceCodeForRefreshToken: true,
    },
    dev: {},
    local: {},
  },
  BLOCKCHAIN: {
    prod: {
      orders_live_url: 'https://live.blockcypher.com/btc/tx',
    },
    stage: {
      orders_live_url: 'https://live.blockcypher.com/btc/tx',
    },
    qa: {
      orders_live_url: 'https://live.blockcypher.com/btc/tx',
    },
    dev: {
      orders_live_url: 'https://live.blockcypher.com/btc/tx',
    },
    local: {
      orders_live_url: 'https://live.blockcypher.com/btc/tx',
    },
  },
  FIREBASE: {
    prod: {},
    stage: {
      apiKey: 'AIzaSyCZ1iQSk1VeaqFz3v4raOSjLqlTeYpMLgM',
      authDomain: 'vita-wallet-api-qa-2.firebaseapp.com',
      databaseURL: 'https://vita-wallet-api-qa-2.firebaseio.com',
      projectId: 'vita-wallet-api-qa-2',
      storageBucket: 'vita-wallet-api-qa-2.appspot.com',
      messagingSenderId: '1080238478021',
      appId: '1:1080238478021:web:9d969d2e2a43f244',
    },
    qa: {
      apiKey: 'AIzaSyCZ1iQSk1VeaqFz3v4raOSjLqlTeYpMLgM',
      authDomain: 'vita-wallet-api-qa-2.firebaseapp.com',
      databaseURL: 'https://vita-wallet-api-qa-2.firebaseio.com',
      projectId: 'vita-wallet-api-qa-2',
      storageBucket: 'vita-wallet-api-qa-2.appspot.com',
      messagingSenderId: '1080238478021',
      appId: '1:1080238478021:web:9d969d2e2a43f244',
    },
    dev: {},
    local: {
      apiKey: 'AIzaSyCZ1iQSk1VeaqFz3v4raOSjLqlTeYpMLgM',
      authDomain: 'vita-wallet-api-qa-2.firebaseapp.com',
      databaseURL: 'https://vita-wallet-api-qa-2.firebaseio.com',
      projectId: 'vita-wallet-api-qa-2',
      storageBucket: 'vita-wallet-api-qa-2.appspot.com',
      messagingSenderId: '1080238478021',
      appId: '1:1080238478021:web:9d969d2e2a43f244',
    },
  },
  HOTJAR: {
    prod: {
      hjid: 1786890,
      hjsv: 6,
    },
    stage: {
      hjid: 1786890,
      hjsv: 6,
    },
    qa: {
      hjid: 1786890,
      hjsv: 6,
    },
    dev: {},
    local: {},
  },
  INTERCOM: {
    prod: {
      app_id: 'sgfvyf4z',
      custom_launcher_selector: 'intercom_boot',
    },
    stage: {
      app_id: 'sgfvyf4z',
      custom_launcher_selector: 'intercom_boot',
    },
    qa: {
      app_id: 'sgfvyf4z',
      custom_launcher_selector: 'intercom_boot',
    },
    dev: {},
    local: {},
  },
  DLOCAL: {
    prod: {
      url: 'https://js.dlocal.com/',
      key: '822a1620-ef62-497b-949d-5a84d5a22ebc',
    },
    stage: {
      url: 'https://js-sandbox.dlocal.com',
      key: '35a9bf68-3efa-42dd-a1bd-7ed4f036e891',
    },
    qa: {
      url: 'https://js-sandbox.dlocal.com',
      key: '35a9bf68-3efa-42dd-a1bd-7ed4f036e891',
    },
    dev: {
      url: 'https://js-sandbox.dlocal.com',
      key: '35a9bf68-3efa-42dd-a1bd-7ed4f036e891',
    },
    local: {
      url: 'https://js-sandbox.dlocal.com',
      key: '35a9bf68-3efa-42dd-a1bd-7ed4f036e891',
    },
  },
};

export default {
  ENDPOINTS,
  ENV,
  API_VERSION,
};

export const INDEX = {
  prod: true,
  stage: false,
  qa: false,
  dev: false,
  local: false
};

export const HAS_INDEX = INDEX[ENV];

export const ENCRYPTED_STORAGE = {
  prod: {
    key: ENCRYPT["prod"].key,
    is: true,
  },
  stage: {
    key: ENCRYPT["stage"].key,
    is: true,
  },
  qa: {
    key: ENCRYPT["qa"].key,
    is: true,
  },
  dev: {
    key: ENCRYPT["dev"].key,
    is: false,
  },
  local: {
    key: ENCRYPT["local"].key,
    is: false,
  },
}
