import { useEffect, useState, useMemo } from 'react';
import {
  getDeviceName,
  getDeviceId,
  getUniqueId,
  hasDynamicIsland,
  hasNotch as hasDynamicNotch,
} from 'react-native-device-info';

const useDeviceInfo = () => {

  const [deviceName, setDeviceName] = useState('');
  const deviceId = getDeviceId();
  const uniqueId = getUniqueId();
  const hasIsland = useMemo(() => hasDynamicIsland ? hasDynamicIsland() : false, []);
  const hasNotch = useMemo(() => hasDynamicNotch ? hasDynamicNotch() : false, []);

  useEffect(() => {
    getDeviceName().then(name => {
      setDeviceName(name);
    });

    return () => {
      setDeviceName('');
    };
  }, []);

  return {
    deviceName,
    deviceId,
    uniqueId,
    hasIsland,
    hasNotch,
  };
};

export default useDeviceInfo;