class CIO {
  cio = window._cio;

  __constructor(CIO_SITE_ID, CIO_API_KEY){};

  identify(id, params) {
    if(id) {
      this.cio.identify({
        ...params,
        id: id,
      })
    }
  }

  track(id, params) {
    if(id) {
      this.cio.track(params.name, params.data);
    }
  }

  addDevice(id, token, platform, params) {
    if(id) {
      this.cio.sidentify(token, platform, params);
    }
  }

  trackPageView(id, screen_name) {
    if(id) {
      this.cio.page(screen_name);
    }
  }
}

export default CIO;