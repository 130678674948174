import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import pricesServices from '../services/prices';

import {
  PRICES_KEY,
  PRICES_DEFAULT_VALUE,
  PRICES_TRANSFER_KEY,
  PRICES_TRANSFER_DEFAULT_VALUE,
  // PRICES_EXPIRY_TIME_KEY,
  // PRICES_EXPIRY_TIME_VALUE,
} from './state';

const prices = (useStorage) => {
  const PricesContext = createContext();
  const {Provider} = PricesContext;
  let _isPricesFetch = false;
  let _isTransferPricesFetch = false;

  const PricesProvider = (props) => {
    const [prices, updatePrices, hydrated] = useStorage(
      PRICES_KEY,
      PRICES_DEFAULT_VALUE,
    );

    const [pricesTransfer, updatePricesTransfer, hydratedTransfer] = useStorage(
      PRICES_TRANSFER_KEY,
      PRICES_TRANSFER_DEFAULT_VALUE,
    );

    // const [expiryTimes, updateExpiryTimes, hydratedExpiryTimes] = useStorage(
    //   PRICES_EXPIRY_TIME_KEY,
    //   PRICES_EXPIRY_TIME_VALUE,
    // );

    const value = useMemo(
      () => ({
        prices: prices || PRICES_DEFAULT_VALUE,
        pricesTransfer: pricesTransfer || PRICES_TRANSFER_DEFAULT_VALUE,
        // expiryTimes,
        // updateExpiryTimes,
        updatePrices,
        updatePricesTransfer,
        pricesIsReady: hydrated,
        pricesTransferIsReady: hydratedTransfer,
        // expiryTimesIsReady: hydratedExpiryTimes,
      }),
      [
        prices,
        pricesTransfer,
        // expiryTimes,
        // updateExpiryTimes,
        updatePrices,
        updatePricesTransfer,
        hydrated,
        hydratedTransfer,
        // hydratedExpiryTimes,
      ],
    );

    return <Provider value={value} {...props} />;
  };

  PricesProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const usePricesContext = () => {
    const context = useContext(PricesContext);

    useEffect(() => {
      return () => {
        _isPricesFetch = false;
        _isTransferPricesFetch = false;
      };
    }, []);

    if (!context) {
      throw new Error('usePricesContext must be used within a PricesContext');
    }

    const {
      prices,
      pricesTransfer,
      // expiryTimes,
      updatePrices,
      updatePricesTransfer,
      // updateExpiryTimes,
      ...rest
    } = context;

    const getPricesContext = useCallback(
      async (headers,  force = false) => {
        if(!_isPricesFetch) {
          if (moment().diff(prices.expiry, 'milliseconds') >= 10 || force) {
            _isPricesFetch = true;
            let newPrices = null;
            let expiry = null;
            let e = {status: null};
            let fetchErrorPrices = '';
  
            try {
              const response = await pricesServices.getPrices(headers);
              expiry = response.valid_until;
              newPrices = response.prices;
              fetchErrorPrices = '';
              // setErrorFetch('');
            } catch (error) {
              e = error;
              if (!newPrices && e.status !== 429) {
                // setErrorFetch('Error al recuperar los precios');
                fetchErrorPrices = 'Recuperando precios...';
                expiry = moment().add(10, 'seconds');
              }
  
              if(e.status === 429) {
                // setErrorFetch('Error 429');
                fetchErrorPrices = 'Error 429';
                expiry = moment().add(15, 'seconds');
              }
            }

            delete newPrices?.prices?.headers;

            if (newPrices) {
              updatePrices({...prices, prices: newPrices, fetchErrorPrices, expiry});
            } else {
              updatePrices({...prices, fetchErrorPrices, expiry});
            }
  
            // if (expiry) {
            //   updateExpiryTimes({...expiryTimes, bitcoin: expiry});
            // }
  
            _isPricesFetch = false;
  
            if(e.status) {
              return Promise.reject(e);
            }
          } else {
            updatePrices({...prices, expiry: moment(prices.expiry).subtract(5, 'seconds')});
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [prices, _isPricesFetch],
    );

    const getTransferPricesContext = useCallback(
      async (headers, force = false) => {
        if(!_isTransferPricesFetch) {
          if (moment().diff(pricesTransfer.expiry, 'milliseconds') >= 10 || force) {
            _isTransferPricesFetch = true;
            let transferPrices = null;
            let expiry = null;
            let e = {status: null};
            let fetchError = '';
            
            try {
              const response = await pricesServices.getTransferPrices(headers);
              expiry = response.valid_until;
              transferPrices = {prices: response};
              fetchError = '';
              // setErrorFetch('');
            } catch (error) {
              e = error;
              if (!transferPrices && e.status !== 429) {
                // setErrorFetch('Error al recuperar los precios');
                fetchError = 'Error al recuperar los precios';
                expiry = moment().add(30, 'seconds');
              }
  
              if(e.status === 429) {
                // setErrorFetch('Error 429');
                fetchError = 'Error 429';
                expiry = moment().add(15, 'seconds');
              }
            }
            
            delete transferPrices?.prices?.headers;

            if (transferPrices) {
              updatePricesTransfer({...pricesTransfer, transferPrices, fetchError, expiry});
            } else {
              updatePricesTransfer({...pricesTransfer, fetchError, expiry});
            }
  
            // if (expiry) {
            //   updateExpiryTimes({...expiryTimes, transfer: expiry});
            // }
  
            _isTransferPricesFetch = false;
  
            if(e.status) {
              return Promise.reject(e);
            }
          } else {
            updatePricesTransfer({...pricesTransfer, expiry: moment(pricesTransfer.expiry).subtract(5, 'seconds')});
          }
        } else {
          moment().diff(pricesTransfer.expiry, 'milliseconds')
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pricesTransfer, _isTransferPricesFetch],
    );

    const cleanPrices = () => {
      updatePrices(PRICES_DEFAULT_VALUE);
      updatePricesTransfer(PRICES_TRANSFER_DEFAULT_VALUE);
    }

    // const cleanTimePrices = () => {
    //   updateExpiryTimes(PRICES_EXPIRY_TIME_VALUE);
    // }

    return {
      ...rest,
      prices: {
        prices: {
          prices: prices.prices, 
          expiry: prices.expiry
        },
        transferPrices: {
          ...pricesTransfer.transferPrices,
          expiry: pricesTransfer.expiry,
        },
        fetchError: pricesTransfer.fetchError,
        fetchErrorPrices: prices.fetchErrorPrices,
      },
      getPricesContext,
      getTransferPricesContext,
      cleanPrices,
      // cleanTimePrices,
    };
  };

  return {
    PricesProvider,
    usePricesContext,
  };
};

export default prices;
