import {StyleSheet} from 'react-native';
import {Colors, Fonts} from '../../../resources';

const styles = StyleSheet.create({
  last_session_container: {
    marginTop: 'auto',
    paddingLeft: 13,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: Colors.C_FFFFFF,
  },
  font: {
    fontFamily: Fonts.regular,
  },
  text: {
    color: Colors.C_FFFFFF,
    fontFamily: Fonts.regular,
  },
  underline: {
    color: Colors.C_FFFFFF,
    paddingBottom: 2,
  },
  title: {
    fontFamily: Fonts.bold,
  },
  medium_font: {
    fontFamily: Fonts.medium,
  },
});

export default styles;
