import React, { useEffect } from 'react';
import IndexDBStorage from '@react-native-indexdb-storage/indexdb-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Dumdee, { DUMDEE_CONSTANTS } from 'dumdee';
import { NetworkProvider } from 'react-native-offline';

import useFacebook from 'wallet/hooks/useFacebook';
import AppNavigator from './navigation';
import { ENVIRONMENT, ENCRYPTED_STORAGE_ENV, ENCRYPT_KEY } from './config/constants';
import { networkProps } from './util';

const App = () => {
  const { ANGIE } = DUMDEE_CONSTANTS;
  const { initialateSDK } = useFacebook();

  useEffect(() => {
    initialateSDK();
  }, []);

  return (
    <NetworkProvider {...networkProps}>
      <Dumdee
        env={ENVIRONMENT}
        target={ANGIE}
        storage={IndexDBStorage}
        temporaryStorage={global.sessionStorage}
        isEncrypt={ENCRYPTED_STORAGE_ENV}
        encryptKey={ENCRYPT_KEY}
      >
        <AppNavigator />
      </Dumdee>
    </NetworkProvider>
  );
};

export default App;
