import Config from '../config';
import useResidenceConfig from './useResidenceConfig';

const useCleaner = () => {
  const {
    usePricesContext,
    useBanners,
    useBanks,
    useSession,
    useSettings,
    useFrequentAccounts,
    useRules,
    useUser,
    useVersionsContext,
    useBusinessLots,
    useNotifications,
  } = Config.getInstance().getConfiguration();
  const { sessionClean } = useSession();
  const { clean: cleanSettings } = useSettings();
  const { bannersClean, resetNotificationCoupon } = useBanners();
  const { cleanFrequentAccounts } = useFrequentAccounts();
  const { banksClean } = useBanks();
  const { cleanResidenceConfig } = useResidenceConfig();
  const { cleanRules } = useRules();
  const { cleanPrices } = usePricesContext();
  const { logOut } = useUser();
  const { cleanVersions } = useVersionsContext();
  const { cleanInfoLots } = useBusinessLots();
  const { clearNotifications } = useNotifications();

  const hardLogOut = (isInactive) => {
    logOut(isInactive);
    cleanVersions();
    banksClean();
    cleanPrices();
    sessionClean();
    resetNotificationCoupon();
    cleanInfoLots();
    bannersClean();
    cleanFrequentAccounts();
  };

  const cleanData = () => {
    cleanPrices();
    cleanRules();
    cleanResidenceConfig();
    cleanSettings();
    bannersClean();
    cleanFrequentAccounts();
    banksClean();
    cleanVersions();
    cleanInfoLots();
    clearNotifications();
  };

  return {
    hardLogOut,
    cleanData,
  };
};

export default useCleaner;
