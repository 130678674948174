import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const register = (payload) => {
  return apiCall({
    ...ENDPOINTS.auth.register,
    data: payload,
  });
};

const logIn = (email, password, firebase_token = null, headers, grecaptcha) => {
  return apiCall({
    ...ENDPOINTS.auth.logIn,
    data: { email, password, firebase_token, grecaptcha },
    headers,
  });
};

const logInFromPin = (email, pin) => {
  return apiCall({
    ...ENDPOINTS.auth.logInFromPin,
    data: { email, pin },
  });
};

const recoverPin = (email) => {
  return apiCall({
    ...ENDPOINTS.auth.recoverPin,
    data: { email },
  });
};

const recoverPassword = (payload) => {
  return apiCall({
    ...ENDPOINTS.auth.recoverPassword,
    data: payload,
  });
};

/** Recover password from request
 *  payload = {
 *      data: data,
 *      headers: headers,
 *  }
 */
const recoverPasswordFromRequest = (payload) => {
  return apiCall({
    ...ENDPOINTS.auth.recoverPasswordFromRequest,
    ...payload,
  });
};

/** Get profile from deepLinking
 *  payload = {
 *      data: data,
 *      headers: headers,
 *  }
 */
const getProfileFromDeepLinking = (payload) => {
  return apiCall({
    ...ENDPOINTS.profile.getProfile,
    ...payload,
  });
};

const setPin = (pin, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.activatePinCode,
    data: { pin: pin },
    headers,
  });
};

const activateAutomaticExchange = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.activateAutomaticExchange,
    headers,
  });
};

const deactivateAutomaticExchange = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.deactivateAutomaticExchange,
    headers,
  });
};

const activateDoubleAuthentication = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.activateCodeDoubleAuthentication,
    headers,
  });
};

const deactivateDoubleAuthentication = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.deactivateCodeDoubleAuthentication,
    headers,
  });
};

const confirmDoubleAuthentication = (code, headers, first_time = true) => {
  return apiCall({
    ...ENDPOINTS.profile.confirmCodeDoubleAuthetication,
    headers,
    data: { code, first_time },
  });
};

const sendDoubleAuthentication = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.sendCodeDoubleAuthentication,
    headers,
  });
};

const sendLimitRequest = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.sendLimitRequest,
    headers,
  });
};

const sendLimitRequestTransactions = (headers, limit_type, limit_duration) => {
  const { url } = ENDPOINTS.profile.sendLimitRequestTransactions;
  return apiCall({
    ...ENDPOINTS.profile.sendLimitRequestTransactions,
    url: url
      .replace(':limit_type', limit_type)
      .replace(':limit_duration', limit_duration),
    headers,
  });
};

// const getProvinces = (headers, country) => {
//   const {url} = ENDPOINTS.settings.provinces;
//   return apiCall({
//     ...ENDPOINTS.settings.provinces,
//     url: url.replace('${country}', country),
//     headers,
//   });
// };

const confirmLoginDoubleAuthentication = (code, headers, first_time = true, email, firebase_token = null) => {
  return apiCall({
    ...ENDPOINTS.profile.authConfirmCodeDoubleAuthetication,
    headers,
    data: { code, first_time, email, firebase_token },
  });
};

const resendLoginDoubleAuthentication = (email) => {
  return apiCall({
    ...ENDPOINTS.profile.authResendCodeDoubleAuthetication,
    data: { email },
  });
};

/** Get profile from deepLinking
 *  payload = {
 *      data: {
 *          bank: string,
 *          type_account_bank: string,
 *          account_bank: string,
 *      },
 *      headers: headers,
 *  }
 */
const saveProfileBank = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.profile.saveProfileBank,
    headers,
    data,
  });
};

const sendVerification = (payload, headers) => {
  let formData = new FormData();

  const IGNORE_KEYS = ['document_image', 'back_document_image', 'facial_image'];

  for (const key in payload) {
    if (payload[key] !== undefined && payload[key] !== null) {
      if (typeof payload[key] === 'object' && !IGNORE_KEYS.includes(key)) {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }
  }

  return apiCall({
    ...ENDPOINTS.profile.sendVerification,
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const sendVerificationBusiness = (payload, headers) => {
  let formData = new FormData();

  const IGNORE_KEYS = [
    'document_image',
    'back_document_image',
    'facial_image',
    'statement_of_funds_document',
    'legal_representative_proof_document',
    'extra_document',
    'company_statute',
    'tax_identity_document',
    'ubo_document_image',
    'ubo_back_document_image',
  ];

  for (const key in payload) {
    if (payload[key] !== undefined && payload[key] !== null) {
      if (typeof payload[key] === 'object' && !IGNORE_KEYS.includes(key)) {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }
  }

  return apiCall({
    ...ENDPOINTS.profile.sendVerificationBusiness,
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getUbo = (id, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.getUbo(id),
    headers,
  });
};

const deleteUbo = (id, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.deleteUbo(id),
    headers,
  });
};

const getProfile = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.getProfile,
    headers,
  });
};

const findUserFromEmailOrWallet = (headers, address, isVitaCard) => {
  const params = {
    address: address,
  }

  if (isVitaCard) {
    params.is_vita_card = true;
  }

  return apiCall({
    ...ENDPOINTS.profile.findUserFromEmailOrWallet(params),
    headers,
  });
};

const deleteCreditCard = (card, headers) => {
  let { url } = ENDPOINTS.profile.deleteCreditCard;
  url = url.replace(':card', card);
  return apiCall({
    ...ENDPOINTS.profile.deleteCreditCard,
    url,
    headers,
  });
};

const generatePaymentLink = (amount, email, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.generatePaymentLink,
    headers,
    data: { email, amount },
  });
};

const ipsWhiteList = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.ipsWhiteList,
    headers,
  });
};

const saveIps = (ips, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.saveIps,
    headers,
    data: { ...ips },
  });
};

const sendCredential = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.sendCredential,
    headers,
  });
};

const getBusinessCredentials = (token, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.getBusinessCredentials,
    headers,
    data: { token },
  });
};

const startVerification = (payload, headers) => {
  return apiCall({
    ...ENDPOINTS.profile.startVerification,
    data: {
      verification_type: payload,
    },
    headers: {
      ...headers,
    },
  });
};

// const getFavoriteAccountsInternal = (headers) => {
//   let { url } = ENDPOINTS.profile.getFavoriteAccounts;
//   url = `${url}?q[account_type_eq]=0`;
//   return apiCall({
//     ...ENDPOINTS.profile.getFavoriteAccounts,
//     url,
//     headers,
//   });
// };

// const getFavoriteAccountsInternational = (headers) => {
//   let { url } = ENDPOINTS.profile.getFavoriteAccounts;
//   url = `${url}?q[account_type_eq]=1`;
//   return apiCall({
//     ...ENDPOINTS.profile.getFavoriteAccounts,
//     url,
//     headers,
//   });
// };

const createFavoriteAccount = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.profile.createFavoriteAccount,
    headers,
    data,
  });
};

const updateFavoriteAccount = (headers, id, data) => {
  let { url } = ENDPOINTS.profile.updateFavoriteAccount;
  url = url.replace(':id', id);
  return apiCall({
    ...ENDPOINTS.profile.updateFavoriteAccount,
    url,
    headers,
    data,
  });
};

const deleteFavoriteAccount = (headers, id) => {
  let { url } = ENDPOINTS.profile.deleteFavoriteAccount;
  url = url.replace(':id', id);
  return apiCall({
    ...ENDPOINTS.profile.deleteFavoriteAccount,
    url,
    headers,
  });
};

const verificationStatus = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.verificationStatus,
    headers,
  });
};

const requestBiometricVerification = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.profile.requestBiometricVerification,
    headers,
    data,
  });
};

const createBankAccount = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.profile.createBankAccount,
    headers,
    data,
  });
};

const updateBankAccount = (headers, id, data) => {
  let { url } = ENDPOINTS.profile.updateBankAccount;
  url = url.replace(':id', id);
  return apiCall({
    ...ENDPOINTS.profile.updateBankAccount,
    url,
    headers,
    data,
  });
};

const deleteBankAccount = (headers, id) => {
  let { url } = ENDPOINTS.profile.deleteBankAccount;
  url = url.replace(':id', id);
  return apiCall({
    ...ENDPOINTS.profile.deleteBankAccount,
    url,
    headers,
  });
};

// const getBankAccount = (headers, data) => {
//   return apiCall({
//     ...ENDPOINTS.profile.getBankAccount,
//     headers,
//     data,
//   });
// };

const generateBtcAddress = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.createBtcAddress,
    headers,
  });
};

const changeStatusTruspillot = (headers, status) => {
  return apiCall({
    ...ENDPOINTS.profile.changeStatusTruspillot,
    data: { status },
    headers,
  });
};

const confirmAuthenticationCode = (code, headers) => {
  return apiCall({
    ...ENDPOINTS.validation.confirmValidationPin,
    headers,
    data: { code },
  });
};

const getAuthenticationCode = (headers) => {
  return apiCall({
    ...ENDPOINTS.validation.getValidationPin,
    headers,
  });
};

const logOut = (headers) => {
  return apiCall({
    ...ENDPOINTS.auth.logOut,
    headers,
  });
};

const firstTransaction = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.firstTransaction,
    headers,
  });
};

const getBiometricStatus = headers => {
  return apiCall({
    ...ENDPOINTS.profile.getBiometricStatus,
    headers,
  });
};

const getAvatarsAvailable = headers => {
  return apiCall({
    ...ENDPOINTS.avatar.getAvatarsAvailable,
    headers,
  });
};

const setUserAvatar = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.avatar.setUserAvatar,
    headers,
    data,
  });
};

const getOccupations = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.getOccupations,
    headers,
  });
};

const handleSetDefaultCurrency = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.profile.handleSetDefaultCurrency,
    headers,
    data,
  });
};

const getUserBalances = headers => {
  return apiCall({
    ...ENDPOINTS.profile.getUserBalances,
    headers,
  });
};

const getListFavoriteAccounts = (headers, params) => {
  const { type, count = 10, page = 1, countryCode = '' } = params;
  let { url } = ENDPOINTS.profile.getFrecuentAccount;
  url = `${url}?q[type]=${type}&page=${page}&count=${count}&q[iso_code_country_eq]=${countryCode}`;

  return apiCall({
    ...ENDPOINTS.profile.getFrecuentAccount,
    url,
    headers,
  });
};

const getFrecuentAccount = (headers, params) => {
  const { type, id} = params;
  let { url } = ENDPOINTS.profile.getFrecuentAccount;
  url = `${url}/${id}?q[type]=${type}`;

  return apiCall({
    ...ENDPOINTS.profile.getFrecuentAccount,
    url,
    headers,
  });
};

const searchFrequentAccount = (headers, params) => {
  return apiCall({
    ...ENDPOINTS.profile.findFrecuentAccount(params),
    headers,
  });
};

export default {
  deleteBankAccount,
  updateBankAccount,
  // getBankAccount,
  createBankAccount,
  // getFavoriteAccountsInternal,
  // getFavoriteAccountsInternational,
  createFavoriteAccount,
  updateFavoriteAccount,
  deleteFavoriteAccount,
  register,
  startVerification,
  getBusinessCredentials,
  sendCredential,
  saveIps,
  ipsWhiteList,
  logIn,
  findUserFromEmailOrWallet,
  recoverPassword,
  recoverPasswordFromRequest,
  getProfileFromDeepLinking,
  setPin,
  activateAutomaticExchange,
  deactivateAutomaticExchange,
  logInFromPin,
  recoverPin,
  sendVerification,
  sendVerificationBusiness,
  getUbo,
  deleteUbo,
  activateDoubleAuthentication,
  deactivateDoubleAuthentication,
  confirmDoubleAuthentication,
  sendDoubleAuthentication,
  sendLimitRequest,
  sendLimitRequestTransactions,
  saveProfileBank,
  getProfile,
  deleteCreditCard,
  generatePaymentLink,
  verificationStatus,
  requestBiometricVerification,
  generateBtcAddress,
  changeStatusTruspillot,
  confirmAuthenticationCode,
  getAuthenticationCode,
  logOut,
  confirmLoginDoubleAuthentication,
  resendLoginDoubleAuthentication,
  firstTransaction,
  getBiometricStatus,
  getAvatarsAvailable,
  setUserAvatar,
  getOccupations,
  handleSetDefaultCurrency,
  getUserBalances,
  getListFavoriteAccounts,
  getFrecuentAccount,
  searchFrequentAccount,
};
