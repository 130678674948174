import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Switch, Route} from 'react-router';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {UTM_PARAMS} from 'alejandra/navigation/util';

import {transformScreenNavigation} from '../../../utils';
import {objectToQueryString} from 'wallet/util';

const StackComponent = (props) => {
  const {component, navigation} = props;
  const params = useParams();
  const Component = component;
  const query = new URLSearchParams(useLocation().search);
  for (let key of query.keys()) {
    params[key] = query.get(key);
  }

  return <Component navigation={navigation} route={{params}} />;
};

const Navigator = (props) => {
  const {children} = props;
  return (
    <Switch>
      <>{children}</>
    </Switch>
  );
};

Navigator.propTypes = {
  initialRouteName: PropTypes.string,
  children: PropTypes.any,
};

Navigator.defaultProps = {
  initialRouteName: '',
  children: <></>,
};

const Screen = (props) => {
  const {name, component} = props;
  const [utm_params] = useState(() => {
    if(window && window.sessionStorage) {
      return window.sessionStorage.getItem(UTM_PARAMS);
    }
  });

  let history = useHistory();

  const navigation = {
    setOptions: (config) => {
      const {title} = config;
      document.title = title;
    },
    setParams: (config) => {
      const params = objectToQueryString(config);
      // var newurl =  `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params}`;
      // window.history.pushState({path:newurl},'',newurl);
      history.push({
        pathname: window.location.pathname,
        search: `?${params}${utm_params !== '' ? `&${utm_params}` : ''}`,
      });
    },
    reset: (config) => {
      const {routes} = config;
      history.replace(`${transformScreenNavigation[routes[0].name]}?${objectToQueryString(routes[0].params)}${utm_params !== '' ? `&${utm_params}` : ''}`);
    },
    dispatch: (config) => {
      window.history.replaceState({}, document.title);
      const {routes} = config;
      const route = routes[routes.length - 1];
      history.replace(`${transformScreenNavigation[route.name]}?${objectToQueryString(route.params)}${utm_params !== '' ? `&${utm_params}` : ''}`);
      // history.replace(
      //   `${transformScreenNavigation[route.name]}${
      //     route.hasOwnProperty('params')
      //       ? `?${objectToQueryString(route.params)}`
      //       : ''
      //   }${utm_params !== '' ? `&${utm_params}` : ''}`,
      // );
    },
    navigate: (scene, params) => {
      history.push({
        pathname: transformScreenNavigation[scene],
        search: `?${objectToQueryString(params)}${utm_params !== '' ? `&${utm_params}` : ''}`,
      });
    },
  };

  return (
    <Route path={name} exact>
      <StackComponent component={component} navigation={navigation} />
    </Route>
  );
};

Screen.propTypes = {
  component: PropTypes.any,
  name: PropTypes.string,
};

Screen.defaultProps = {
  component: () => <></>,
  name: '',
};

export const createStackNavigator = () => {
  return {
    Navigator,
    Screen,
  };
};
