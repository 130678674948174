import { useCallback } from 'react';
import Config from '../config';
import useResidenceConfig from '../hooks/useResidenceConfig';
import { numberWithMaxDecimals } from 'wallet/util';

const useAmounts = () => {
  const {
    useRules,
    env: { apiUrl }
  } = Config.getInstance().getConfiguration();

  const { rules, vitaCountries, cryptoExistent } = useRules();
  const {
    currency,
    iso_code,
    currency_decimals,
    currency_name,
    currency_plural_name,
    flag_url,
    is_transfer,
    name,
  } = useResidenceConfig();
  const residence = {
    currency_code: currency.toUpperCase(),
    currency_decimals: currency_decimals,
    currency_name: currency_name,
    currency_name_plural: currency_plural_name,
    flag_url: flag_url,
    is_transfer: is_transfer,
    iso_code: iso_code,
    name: name,
  };
  const us_config = {
    currency_code: "USD",
    currency_decimals: 2,
    currency_name: "Dólar estadounidense",
    currency_name_plural: "Dolares estadounidenses",
    flag_url: `${apiUrl.replace('io/api', 'io/')}mailers/flags/US.png`,
    iso_code: "US",
    name: "Estados Unidos",
  };
  const {
    transferRules: {
      rules: country_rules
    }
  } = rules;

  const decimalAmounts = useCallback((amount = 0, country_code = 'cl', zero = false) => {
    country_code = country_code.toLowerCase();

    const precision = country_rules[country_code] && !zero ? country_rules[country_code].currency_decimals : 0;

    return numberWithMaxDecimals(amount, precision);
  }, [country_rules]);

  const countryByCurrencyCode = useCallback((currency_code = 'CLP') => {
    let country = {};

    currency_code = currency_code.toUpperCase();

    if (currency.toUpperCase() === currency_code && currency_code !== 'USD') {
      country = country_rules[iso_code.toLowerCase()];
    } else if (currency_code === 'USD') {
      country = country_rules['us'] ? country_rules['us'] : us_config;
    } else if (cryptoExistent.includes(currency_code)) {
      country = country_rules[currency_code.toLowerCase()];
    } else {
      country = vitaCountries.find(val => {
        return val.currency_code === currency_code
      })
    }

    return country ? country : residence;
  }, [
    currency,
    country_rules,
    cryptoExistent,
    vitaCountries,
  ]);

  const countryByIsoCode = useCallback((iso_code = 'CL') => {
    iso_code = iso_code ? iso_code.toLowerCase() : 'cl';
    return country_rules[iso_code] ? country_rules[iso_code] : residence;
  }, [country_rules]);

  const countryFlagByIsoCode = useCallback((iso_code = 'CL') => {
    return countryByIsoCode(iso_code).flag_url;
  }, [countryByIsoCode]);

  const countryFlagByCurrencyCode = (currency_code = 'CLP') => countryByCurrencyCode(currency_code).flag_url;

  return {
    decimalAmounts,
    countryByCurrencyCode,
    countryByIsoCode,
    countryFlagByIsoCode,
    countryFlagByCurrencyCode,
  };
};

export default useAmounts;
