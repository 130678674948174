import React from 'react';
import ReactDOM from 'react-dom';
import Angie from './angie';
import reportWebVitals from './reportWebVitals';
import { HAS_INDEX } from './alejandra/config/env';
// import {ENVIRONMENT} from './alejandra/config/constants';
// import {PROD_ENVIRONMENT} from './dumdee/v2/config/constants';

// if (ENVIRONMENT === PROD_ENVIRONMENT) {
//     global.console.log = () => {};
// }

if (!HAS_INDEX) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('name', 'robots');
  metaTag.setAttribute('content', 'noindex');
  document.head.appendChild(metaTag);
}

ReactDOM.render(
  <React.StrictMode>
    <Angie />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
