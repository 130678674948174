import firebase from 'firebase';
import {FIREBASE_CONFIGURATION} from '../../../../alejandra/config/constants';

firebase.initializeApp(FIREBASE_CONFIGURATION);

const messaging = () => {
  const askPermissions = async () => {
    try {
      // eslint-disable-next-line no-undef
      await Notification.requestPermission().then(async (permission) => {
        if (permission === 'denied') {
          return;
        } else if (permission === 'default') {
          return;
        }
      });
    } catch (error) {}
  };

  const onMessage = (callback) => {
    try {
      if (firebase.messaging.isSupported()) {
        const messagingHandler = firebase.messaging();
        messagingHandler.onMessage((payload) => {
          callback(payload);
        });
      }
    } catch (error) {}
  };

  const getToken = async () => {
    let token = '';
    try {
      const messagingHandler = firebase.messaging();
      token = await messagingHandler.getToken();
      return token;
    } catch (error) {}
    return token;
  };

  return {
    askPermissions,
    onMessage,
    getToken,
  };
};

export default messaging;
