import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';

import Config from '../config';

import userService from '../services/user';
import vitaCardService from '../services/vitaCard';
import useEncrypt from '../hooks/useEncrypt';
import { USER_KEY, USER_DEFAULT_VALUE } from './state';

const user = (useStorage) => {
  const UserContext = createContext();
  const { Provider } = UserContext;

  const UserProvider = (props) => {
    const [user, updateUser, hydrated] = useStorage(
      USER_KEY,
      USER_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        user: user || USER_DEFAULT_VALUE,
        updateUser,
        userIsReady: hydrated,
      }),
      [user, updateUser, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  UserProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useUser = () => {
    const context = useContext(UserContext);
    const { encrypt, decrypt } = useEncrypt();
    const { useResidenceConfigContext } = Config.getInstance().getConfiguration();
    const { cleanResidenceConfig, residenceConfig: { currencies_available } } = useResidenceConfigContext();

    if (!context) {
      throw new Error('useUser must be used within a UserProvider');
    }

    const { user, updateUser: update, userIsReady } = context;

    const updateUser = useCallback(
      (data) => {
        const newValue = Object.assign({}, data);
        const { attributes } = newValue;
        const { email } = attributes;
        delete newValue.attributes;

        let userPayload = {};

        if (user) {
          userPayload = {
            ...user,
            ...newValue,
            ...attributes,
          };
        } else {
          userPayload = {
            ...newValue,
            ...attributes,
          };
        }

        if (user?.profile_verification !== userPayload.profile_verification && userPayload.profile_verification === 'accepted') {
          cleanResidenceConfig();
        }

        if (userPayload.hasOwnProperty('headers') && typeof userPayload.headers === 'object') {
          userPayload.headers = encrypt(
            JSON.stringify({
              ...userPayload.headers,
              uid: email,
            })
          );
        }

        delete userPayload.balances.vita_card;
        userPayload.handle_default_fiat_currency = userPayload.default_fiat_currency || userPayload.default_currency;
        userPayload.handle_default_crypto_currency = userPayload.default_crypto_currency || 'btc';

        if (userPayload.category === 'business' && !!userPayload.business_user?.lr_phone_number) {
          userPayload.business_user.lr_phone = userPayload.business_user.lr_phone_number;
          delete userPayload.business_user.lr_phone_number;
        }

        update(userPayload);
      },
      [user, update],
    );

    const userDecrypt = useMemo(() => {
      return user ? {
        ...user,
        headers: JSON.parse(decrypt(user.headers))
      } : user;
    }, [user]);

    const logOut = useCallback((isInactive) => {
      if (userDecrypt) {
        userService.logOut(userDecrypt.headers);
      }
      update(isInactive ? false : USER_DEFAULT_VALUE);
    }, [update, userDecrypt]);

    const updateProfile = useCallback(async () => {
      try {
        const response = await userService.getProfile(userDecrypt.headers);
        updateUser(response.data);

        return Promise.resolve(response.data);
      } catch (error) {
        if (error.status.toString() === '401' && Platform.OS !== 'web') {
          logOut();
        }

        return Promise.reject(error);
      }
    }, [updateUser, userDecrypt]);

    const updateFirstTransaction = useCallback(async () => {
      try {
        const response = await userService.firstTransaction(userDecrypt.headers);
        updateUser({
          attributes: {
            ...userDecrypt,
            first_transaction: response.first_transaction
          }
        });

        return Promise.resolve({ first_transaction: response.first_transaction });
      } catch (error) {
        if (error.status.toString() === '401' && Platform.OS !== 'web') {
          logOut();
        }

        return Promise.reject(error);
      }
    }, [updateUser, userDecrypt]);

    const updateBiometricStatus = useCallback(async () => {
      try {
        const response = await userService.getBiometricStatus(userDecrypt.headers);
        updateUser({
          attributes: {
            ...userDecrypt,
            biometric_verification: response.status
          }
        });

        return Promise.resolve({
          biometric_verification: response.status,
          biometric_message: response.biometric_message,
          biometric_title: response.biometric_title
        });
      } catch (error) {
        if (error.status.toString() === '401' && Platform.OS !== 'web') {
          logOut();
        }

        return Promise.reject(error);
      }
    }, [updateUser, userDecrypt]);

    const updateBalances = useCallback(async () => {
      try {
        if (!userDecrypt) return null;

        const response = await userService.getUserBalances(userDecrypt.headers);
        updateUser({
          attributes: {
            ...userDecrypt,
            balances: response.balances,
          }
        });
      } catch (error) {
        if (error.status.toString() === '401' && Platform.OS !== 'web') {
          logOut();
        }

        return Promise.reject(error);
      }
    }, [updateUser, userDecrypt]);

    const getCardData = useCallback(async () => {
      try {
        if (!userDecrypt) return null;

        const response = await vitaCardService.getCardData(userDecrypt.headers);
        updateUser({
          attributes: {
            ...userDecrypt,
            ...response?.data?.attributes
          }
        });
      } catch (error) {
        if (error.status.toString() === '401' && Platform.OS !== 'web') {
          logOut();
        }

        return Promise.reject(error);
      }
    }, [updateUser, userDecrypt]);

    const isActivableBalance = useMemo(() => {
      try {
        return Object.keys(userDecrypt.balances)?.length < currencies_available?.length || false
      } catch {
        return false;
      }
    }, [currencies_available, userDecrypt]);

    return {
      userIsReady,
      isActivableBalance,
      user: userDecrypt,
      updateUser,
      updateBiometricStatus,
      logOut,
      updateProfile,
      updateFirstTransaction,
      updateBalances,
      getCardData
    };
  };

  return {
    UserProvider,
    useUser,
  };
};

export default user;
