import { useCallback } from 'react';

import Config from '../config';
import transactionsService from '../services/transactions';
import cryptoService from '../services/crypto';
import userService from '../services/user';

const useServices = () => {
  const { useUser, env } = Config.getInstance().getConfiguration();
  const { user, updateProfile } = useUser();

  const createWithdrawal = useCallback(
    (data) => {
      return transactionsService.createWithdrawal(user.headers, data);
    },
    [user],
  );

  const createSent = useCallback(
    (data) => {
      return transactionsService.createSent(user.headers, data);
    },
    [user],
  );

  const getTransactions = useCallback(() => {
    return transactionsService.getTransactions(user.headers);
  }, [user]);

  const sendLimitRequest = useCallback(() => {
    return userService.sendLimitRequest(user.headers);
  }, [user]);

  const sendLimitRequestTransactions = useCallback(
    (limit_type, limit_duration) => {
      return userService.sendLimitRequestTransactions(
        user.headers,
        limit_type,
        limit_duration,
      );
    },
    [user],
  );

  const activateDoubleAuthentication = useCallback(() => {
    return userService.activateDoubleAuthentication(user.headers);
  }, [user]);

  const deactivateDoubleAuthentication = useCallback(() => {
    return userService.deactivateDoubleAuthentication(user.headers);
  }, [user]);

  const deactivateAutomaticExchange = useCallback(async () => {
    try {
      const response = await userService.deactivateAutomaticExchange(
        user.headers,
      );
      await updateProfile();
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [updateProfile, user]);

  const activateAutomaticExchange = useCallback(async () => {
    try {
      const response = userService.activateAutomaticExchange(user.headers);
      await updateProfile();
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [updateProfile, user]);

  const findUserFromEmailOrWallet = useCallback(
    (address, isVitaCard = false) => {
      return userService.findUserFromEmailOrWallet(
        user.headers,
        address,
        isVitaCard,
      );
    },
    [user],
  );

  const sendVerification = useCallback(
    (payload) => {
      return userService.sendVerification(payload, user.headers);
    },
    [user],
  );

  const sendVerificationBusiness = useCallback(
    (payload) => {
      return userService.sendVerificationBusiness(payload, user.headers);
    },
    [user],
  );

  const deleteUbo = useCallback((id) => {
    return userService.deleteUbo(id, user.headers);
  }, [user])

  const deleteCreditCard = useCallback(
    (card) => {
      return userService.deleteCreditCard(card, user.headers);
    },
    [user],
  );

  const generatePaymentLink = useCallback(
    async (amount) => {
      try {
        const response = await userService.generatePaymentLink(
          amount,
          user.email,
          user.headers,
        );
        const url = `${env.webAppUrl}/payment-gateway/${response.link_charge.token}`;
        return Promise.resolve(url);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [env, user],
  );

  const saveIps = useCallback(
    async (ips) => {
      try {
        const response = await userService.saveIps(ips, user.headers);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [user],
  );

  const sendCredential = useCallback(async () => {
    try {
      const response = await userService.sendCredential(user.headers);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user]);

  const ipsWhiteList = useCallback(async () => {
    try {
      const response = await userService.ipsWhiteList(user.headers);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user]);

  const getBusinessCredentials = useCallback(
    async (token) => {
      try {
        const response = await userService.getBusinessCredentials(
          token,
          user.headers,
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    [user],
  );

  const startVerification = useCallback(
    (payload) => {
      return userService.startVerification(payload, user.headers);
    },
    [user],
  );

  const verificationStatus = useCallback(async () => {
    try {
      const response = await userService.verificationStatus(user.headers);
      return response;
    } catch (error) {
      return error;
    }
  }, [user]);

  const biometricRequest = useCallback(async (source_biometric) => {
    try {
      const response = await userService.requestBiometricVerification(user.headers, { source_biometric });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user]);

  const generateBtcAddress = useCallback(() => {
    return userService.generateBtcAddress(user.headers);
  }, [user]);

  const changeStatusTruspillot = useCallback(async (statusPilot) => {
    try {
      const response = await userService.changeStatusTruspillot(
        user.headers,
        statusPilot,
      );
      return Promise.resolve(response.status_valoration);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user]);

  const getCryptoNetworks = useCallback(async (params) => {
    try {
      const response = await cryptoService.getNetworks(user.headers, params);
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user]);

  const getOccupations = useCallback(async () => {
    try {
      const response = await userService.getOccupations(user.headers);
      delete response.headers;
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user]);

  return {
    startVerification,
    createWithdrawal,
    getBusinessCredentials,
    sendCredential,
    saveIps,
    ipsWhiteList,
    createSent,
    findUserFromEmailOrWallet,
    getTransactions,
    sendLimitRequest,
    activateDoubleAuthentication,
    deactivateDoubleAuthentication,
    deactivateAutomaticExchange,
    activateAutomaticExchange,
    sendVerification,
    sendVerificationBusiness,
    deleteUbo,
    deleteCreditCard,
    generatePaymentLink,
    verificationStatus,
    biometricRequest,
    sendLimitRequestTransactions,
    generateBtcAddress,
    changeStatusTruspillot,
    getCryptoNetworks,
    getOccupations,
  };
};

export default useServices;
