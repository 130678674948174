import { useCallback, useReducer, useEffect } from 'react';

import Config from '../config';
import transactionsService from '../services/transactions';
import powwiService from '../services/powwi';
import useAmounts from './useAmounts';
import { formatMoney } from '../utils';

const AMOUNT_CHANGE = 'useRecharge/AMOUNT_CHANGE';
const SHOW_TRANSBANK = 'useRecharge/SHOW_TRANSBANK';
const HIDDEN_TRANSBANK = 'useRecharge/HIDDEN_TRANSBANK';
const CHANGE_BANK = 'useRecharge/CHANGE_BANK';
const CHANGE_COMISION_PROVIDER = 'useRecharge/CHANGE_COMISION_PROVIDER';
const MIN_AMOUNT_CHANGE = 'useRecharge/MIN_AMOUNT_CHANGE';

const reducer = (state, action) => {
  const { payload = {} } = action;
  switch (action.type) {
    case MIN_AMOUNT_CHANGE: {
      const { currency, minimumAmount, currency_decimals, roundType } = payload;
      return {
        ...state,
        minimumAmount,
        minimumAmountLabel: formatMoney(minimumAmount, currency, currency_decimals.currency_decimals),
        sendCurrencyDecimals: currency_decimals.currency_decimals,
        roundType,
      }
    }
    case AMOUNT_CHANGE: {
      const { sendCurrencyDecimals, roundType, minimumAmount } = state;
      const { value, currency } = payload;
      const amount = value;
      const amountLabel = formatMoney(amount, currency, sendCurrencyDecimals);
      const { commission, typeComision } = state;
      let commissionAmount;

      if (typeComision === 'percentage') {
        commissionAmount = (amount * commission) / 100;
      } else {
        value !== 0 && (commissionAmount = commission);
      }

      if (amount >= minimumAmount) {
        switch (roundType) {
          case 'up_round':
            commissionAmount = Math.ceil(commissionAmount);
            break;
          case 'down_round':
            commissionAmount = Math.floor(commissionAmount);
            break;
          default:
            break;
        }
      }

      const commissionAmountLabel = formatMoney(
        commissionAmount,
        currency,
        sendCurrencyDecimals,
      );

      const total = amount + commissionAmount;
      const totalLabel = formatMoney(total, currency, sendCurrencyDecimals);

      return {
        ...state,
        amount,
        amountLabel,
        commissionAmount,
        commissionAmountLabel,
        total,
        totalLabel,
      };
    }
    case CHANGE_COMISION_PROVIDER: {
      const { value, type } = payload;
      const commission = parseFloat(value);
      const typeComision = type;

      return {
        ...state,
        commission,
        typeComision,
      }
    }
    case CHANGE_BANK: {
      const { bank_key, bank_value } = payload;

      state.bank.id = bank_key ? bank_key : state.bank.id;
      state.bank.value = bank_value ? bank_value : state.bank.value;

      return {
        ...state,
      }
    }
    case SHOW_TRANSBANK: {
      const { url, token, keyName } = payload;
      return {
        ...state,
        transbankConfig: {
          isVisible: true,
          url,
          token,
          keyName,
        },
      };
    }
    case HIDDEN_TRANSBANK: {
      return {
        ...state,
        transbankConfig: {
          isVisible: false,
          token: '',
          url: '',
          keyName: '',
        },
      };
    }
    default:
      throw new Error();
  }
};

const useRecharge = ({ minAmount = 1, currency = 'USD', roundType = 'without_round' }) => {
  const { useUser } = Config.getInstance().getConfiguration();
  const { countryByCurrencyCode } = useAmounts();
  const { user, updateProfile } = useUser();
  const { currency_decimals } = countryByCurrencyCode(currency) ? countryByCurrencyCode(currency) : {};

  const [state, dispatch] = useReducer(reducer, {
    total: 0,
    roundType,
    totalLabel: formatMoney(0, currency, currency_decimals),
    amount: 0,
    amountLabel: formatMoney(0, currency, currency_decimals),
    commission: 0,
    typeComision: 'percentage',
    commissionLabel: formatMoney(0, 'usd', 2),
    commissionAmount: 0,
    commissionAmountLabel: formatMoney(0, currency, currency_decimals),
    minimumAmount: minAmount,
    minimumAmountLabel: formatMoney(minAmount, currency, currency_decimals),
    isReadyRecharge: true,
    sendCurrencyDecimals: currency_decimals,
    bank: {
      id: '',
      value: '',
    },
    transbankConfig: {
      isVisible: false,
      token: '',
      url: '',
      keyName: '',
    },
  });

  useEffect(() => {
    let isChange = true;

    if (isChange) {
      changeMinAmount(minAmount, currency, roundType)
    }

    return () => {
      isChange = false;
    }
  }, [minAmount, currency, roundType]);

  const changeMinAmount = (value, currency) => {
    dispatch({
      type: MIN_AMOUNT_CHANGE,
      payload: {
        minimumAmount: value,
        currency_decimals: countryByCurrencyCode(currency) || {},
        currency,
        roundType,
      },
    });
  };

  const changeAmount = (value) => {
    dispatch({
      type: AMOUNT_CHANGE,
      payload: {
        value,
        currency,
      },
    });
  };

  const changeComisionProvider = (value, type) => {
    dispatch({
      type: CHANGE_COMISION_PROVIDER,
      payload: {
        value,
        type,
      },
    });
  };

  const showTransbank = (url, token, keyName) => {
    dispatch({
      type: SHOW_TRANSBANK,
      payload: {
        url,
        token,
        keyName,
      },
    });
  };

  const hiddenTransbank = () => {
    dispatch({ type: HIDDEN_TRANSBANK });
  };

  const createRechargeFromWebpay = useCallback(
    async (data) => {
      try {
        const response = await transactionsService.createRechargeFromWebpay(
          user.headers,
          data,
        );
        await updateProfile();
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [updateProfile, user],
  );

  const createRechargeFromPse = useCallback(
    async (data) => {
      try {
        const response = await powwiService.generateRechargeUrl(
          user.headers,
          data,
        );
        await updateProfile();
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [updateProfile, user],
  );

  const createRechargeFromOneclick = useCallback(
    async (data) => {
      try {
        const response = await transactionsService.createRechargeFromOneclick(
          user.headers,
          data,
        );
        await updateProfile();
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [updateProfile, user],
  );

  const affiliateCreditCard = useCallback(async () => {
    try {
      const response = await transactionsService.affiliateCreditCard(
        user.headers,
      );
      await updateProfile();
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(JSON.stringify(error));
    }
  }, [updateProfile, user]);

  const onSelectBank = useCallback((bank_key, bank_value) => {
    dispatch({
      type: CHANGE_BANK,
      payload: {
        bank_key,
        bank_value,
      },
    });
  }, []);

  const createRechargeFromBinancePay = useCallback(
    async (data) => {
      try {
        const response = await transactionsService.createRechargeFromBinancePay(
          user.headers,
          data,
        );

        await updateProfile();
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [updateProfile, user],
  );

  const createRechargeFromWompi = useCallback(
    async (data) => {
      try {
        const response = await transactionsService.createRechargeFromWompi(
          user.headers,
          data,
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [user],
  );

  const getTokenFromWompi = useCallback(
    async () => {
      try {
        const response = await transactionsService.getTokenFromWompi(user.headers);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [user],
  );

  const getPaymentUrlFromWompi = useCallback(
    async (payment_reference) => {
      try {
        const response = await transactionsService.getPaymentUrlFromWompi(user.headers, { payment_reference });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [user],
  );

  const getPaymentUrlFromSkrill = useCallback(
    async (payload) => {
      try {
        const response = await transactionsService.getPaymentUrlFromSkrill(user.headers, payload);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [user],
  );

  return {
    ...state,
    changeAmount,
    showTransbank,
    hiddenTransbank,
    createRechargeFromWebpay,
    createRechargeFromOneclick,
    affiliateCreditCard,
    createRechargeFromPse,
    onSelectBank,
    createRechargeFromBinancePay,
    getTokenFromWompi,
    createRechargeFromWompi,
    getPaymentUrlFromWompi,
    changeComisionProvider,
    getPaymentUrlFromSkrill,
  };
};

export default useRecharge;
