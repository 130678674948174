import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const createExchange = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createExchange,
    headers,
    data,
  });
};

const createWithdrawal = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createWithdrawal,
    headers,
    data,
  });
};

const createSent = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createSent,
    headers,
    data,
  });
};

const createRechargeFromWebpay = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createRechargeFromWebpay,
    headers,
    data,
  });
};

const createRechargeFromBinancePay = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createRechargeFromBinancePay,
    headers,
    data,
  });
};

const createRechargeFromOneclick = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createRechargeFromOneclick,
    headers,
    data,
  });
};

const createRechargeFromWompi = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createRechargeFromWompi,
    headers,
    data,
  });
};

const getTokenFromWompi = (headers) => {
  return apiCall({
    ...ENDPOINTS.transactions.getTokenFromWompi,
    headers,
  });
};

const getPaymentUrlFromWompi = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.getPaymentUrlFromWompi,
    headers,
    data,
  });
};

const getPaymentUrlFromSkrill = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.getPaymentUrlFromSkrill,
    headers,
    data,
  });
};

const affiliateCreditCard = (headers) => {
  return apiCall({
    ...ENDPOINTS.transactions.affiliateCreditCard,
    headers,
  });
};

const getTransactions = (headers, currency) => {
  return apiCall({
    ...ENDPOINTS.transactions.list(currency),
    headers,
  });
};

const getFilterTransactions = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.filterList(data.startDate, data.endDate, data.currency),
    headers,
  });
};

const sendHistoryEmail = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.sendHistory,
    headers,
    data,
  });
}

const createTransfer = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.createTransfer,
    headers,
    data,
  });
};

const getTransferRules = (headers) => {
  return apiCall({
    ...ENDPOINTS.transactions.getTransferRules,
    headers,
  });
};

const getVitaCountries = (headers) => {
  return apiCall({
    ...ENDPOINTS.transactions.getVitaCountries,
    headers,
  });
};

const paymentInfo = (params) => {
  const url = ENDPOINTS.transactions.paymentInfo.url.replace(':params', params);
  return apiCall({
    ...ENDPOINTS.transactions.paymentInfo,
    url,
  });
};

const createTransferByLotsVitauser = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.create_batch_send,
    headers,
    data,
  });
};

const validateTransferByLotsVitaUser = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.validate_batch_send,
    headers,
    data,
  });
};

const getStatusTransferByLotsVitaUser = (headers) => {
  return apiCall({
    ...ENDPOINTS.transactions.get_status_batch_send,
    headers,
  });
};

const getStatusTransferByLotsInternational = (headers) => {
  return apiCall({
    ...ENDPOINTS.transactions.get_status_batch_send_international,
    headers,
  });
};

const updatePricesLotVitauser = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.update_prices_batch_send,
    headers,
    data,
  });
};

const getRulesBatchInternational = (iso_code) => {
  return apiCall({
    ...ENDPOINTS.transactions.get_rules_batch_international(iso_code),
  });
};

const sendFileBatchInternational = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.send_file_batch_international,
    headers,
    data,
  });
};

const createTransferByLotsInternational = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.create_batch_send_international,
    headers,
    data,
  });
};

const paymentFileBatchInternational = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.transactions.payment_file_batch_international,
    headers,
    data,
  });
};

const transbankPayment = async (
  email,
  total,
  token_payment,
  tokenLinkCharge,
  token,
) => {
  let request = {
    email,
    total,
    source: 'web',
  };

  if (token_payment) {
    request = {
      ...request,
      token_payment,
    };
  }

  if (tokenLinkCharge) {
    request = {
      ...request,
      token_link_charge: tokenLinkCharge,
    };
  }

  if (token) {
    request = {
      ...request,
      token: token,
    };
  }

  return apiCall({
    ...ENDPOINTS.transactions.transbankPayment,
    data: request,
  });
};

export default {
  createExchange,
  createWithdrawal,
  paymentInfo,
  createSent,
  createRechargeFromWebpay,
  createRechargeFromOneclick,
  affiliateCreditCard,
  getTransactions,
  createTransfer,
  getTransferRules,
  getVitaCountries,
  transbankPayment,
  getFilterTransactions,
  sendHistoryEmail,
  createRechargeFromBinancePay,
  createRechargeFromWompi,
  getTokenFromWompi,
  getPaymentUrlFromWompi,
  getPaymentUrlFromSkrill,
  createTransferByLotsVitauser,
  validateTransferByLotsVitaUser,
  getStatusTransferByLotsVitaUser,
  updatePricesLotVitauser,
  getRulesBatchInternational,
  sendFileBatchInternational,
  getStatusTransferByLotsInternational,
  createTransferByLotsInternational,
  paymentFileBatchInternational,
};
